
import { Accordion, AccordionItem } from 'react-light-accordion';
import React, { useState ,useEffect} from 'react';
import Spinner from "../components/Spinner/Spinner";
import config from "../config";

const Faq2 =({categorySlug}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [faqData, setFaq] = useState([]);
    const [faqStatus, setFaqStatus] = useState(null);

    useEffect(() => {
        fetch(`${config.base_url}faq/${categorySlug}`)
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setFaq(result.faq);
              setFaqStatus(result.status)
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, []);

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div>
            <Spinner />
          </div>
        );
      } else {
        if(faqStatus==404){
          return (<>Faq Data not Found</>)
        }
    return (
      <>
  <Accordion className="accordion" atomic={true}>
    {Object.keys(faqData).map((key , i) => {
      const faqItem = faqData[key];
      return (
        <AccordionItem className="accordionItem" key={i} title={faqItem.question}>
          <p>{faqItem.answer}</p>
        </AccordionItem>
      );
    })}
  </Accordion>
</>
    )
      }
}
export default Faq2;