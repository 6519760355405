import React from "react";
import { Link } from "react-router-dom";

const BasicGrid = (props) => {
    const col = props.column ? props.column : 4;
    const small_col = props.small ? `col-${props.small}` : 'col-12';
    const last_col = props.last_full ? `col-12` : '';
    
    console.log(props.grid_items);

    return (
        <>
            <div className="row align-items-stretch justify-content-center text-black">
                {props.grid_items.map((grid_obj, index) => (
                    <div
                        className={`col-md-${col} ${small_col} ${index === props.grid_items.length - 1 ? last_col : ''}`}
                        key={index}
                    >
                        <div className="p-3 h-100">
                            <div className="row flex-column basic-grid">
                                <div className="col-md-12 p-0 text-center mb-2">
                                    <img src={grid_obj.grid_image_url} className="w-100 square-aspect-lg" alt="" />
                                </div>
                                <div className="col-md-12 p-0 text-center">
                                    <h5>{grid_obj.grid_title}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-md-4 text-center">
                    <Link to={"/contact-us"} className="btn hire-us radius-50 my-3">
                        {props.grid_btn ? props.grid_btn : 'BOOK FREE CONSULTATION'}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default BasicGrid;
