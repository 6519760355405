import React, { useState } from "react";
import "../Dropdown/dropdown.css";
import { Link } from "react-router-dom";

const DropdownMenu = (props) => {
  const { handleLinkClick } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showAlwarSubMenu, setAlwarShowSubMenu] = useState(false);

  const handleToggle = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setShowMenu(true);
    }
  };

  const handleSubmenuToggle = (city) => {
    if(city == 'jaipur'){
      setShowSubMenu(true);
      setAlwarShowSubMenu(false);
      
    }
    
    if(city == 'alwar'){
      setAlwarShowSubMenu(true);
      setShowSubMenu(false);
    }
    // if (window.matchMedia("(max-width: 767px)").matches) {
    //   event.stopPropagation();
    //   const submenu = event.currentTarget.querySelector(".dropdown-menu");
    //   console.log(submenu);
    //   if (submenu) {
    //     submenu.classList.toggle("show");
    //   }
    // }
  };

  return (
    <li className=" nav-item dropdown   has-children" onClick={handleToggle}>
      <button className="nav-link " data-toggle="dropdown">
        Cities
      </button>
      <ul className={`dropdown-menu ${showMenu  ? "show" : ""} sub-menu  `}>
        <li
          className="dropdown-item has-children"
          onClick={() => handleSubmenuToggle('jaipur')}
          onMouseEnter={() => setShowSubMenu(true)}
          onMouseLeave={() => setShowSubMenu(false)}
        >
          Jaipur
          <ul
            className={`dropdown-menu mt-1 ${
              showSubMenu ? "show" : ""
            } sub-menu`}
          >
            <li className="dropdown-item">
              <Link
                className="dropdown-item"
                onClick={handleLinkClick}
                to="/top-rated-interior-jaipur"
              >
                Interior Designer in Jaipur
              </Link>
            </li>
            <li className="dropdown-item">
              <Link
                className="dropdown-item"
                onClick={handleLinkClick}
                to="/modular-kitchen-jaipur"
              >
                Modular Kitchen in Jaipur
              </Link>
            </li>
          </ul>
        </li>
        <li className="dropdown-item has-children "
        onClick={() => handleSubmenuToggle('alwar')}
        onMouseEnter={() => setAlwarShowSubMenu(true)}
        onMouseLeave={() => setAlwarShowSubMenu(false)}
        >
          Alwar
          <ul
            className={`alwar-nav dropdown-menu mt-1 ${
              showAlwarSubMenu ? "show" : ""
            } sub-menu sub_menu_select`}
          >
            <li className="dropdown-item">
              <Link
                className="dropdown-item d-none"
                onClick={handleLinkClick}
                to="/interior-designers-in-alwar"
              >
                Interior Designer in Alwar
              </Link>
            </li>
            <li className="dropdown-item">
              <Link
                className="dropdown-item"
                onClick={handleLinkClick}
                to="/modular-kitchen-in-alwar"
              >
                Modular Kitchen in Alwar
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  );
};

export default DropdownMenu;
