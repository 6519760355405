import React from 'react'
import Counter_section from '../components/Static-Section/Counter-section'
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom';
import AboutVideo from '../asset/images/aruwa-home-about.mp4'
import CityContact from '../components/CityContact';

const AboutUs = () => {
    const options = {
        items: 1, // Number of items to show
        loop: true, // Enable looping
        autoplay: true, // Enable autoplay
        nav: true,
        dots: false,
        autoplaySpeed: 1000, // Autoplay speed in milliseconds
        mouseDrag: true
    };
    return (
        <>
            <section className=' text-dark'>
                <div className='container py-md-5 p-3'>
                    <div className='row align-items-start'>
                        <div className='col-lg-12'>
                        </div>
                        <div className='col-lg-10'>
                            <h1 className="mb-3">Transforming Ordinary Space to Extraordinary</h1>
                            <h5>Everyone deserves to have a beautiful and functional home, and ARUWA Interior is committed to helping you achieve your dream space. We cater a wide range of products to fit your need, from stylish modular kitchens to complete interior solutions for homes as well as for commercial spaces.</h5>
                        </div>
                        <div className='col-lg-2'>
                            <div className=' text-center'>
                                <img src={process.env.PUBLIC_URL + '/asset/images/about/about-banner.png'} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Counter_section></Counter_section>

            <OwlCarousel
                responsiveClass
                className=" owl-theme py-5 vw-100 about-slider gray-background"
                {...options}
            >
                {
                    <>
                        <div className="item p-0 ">
                            <div className='about-slider-item'>
                                <div className='container'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-4'>
                                            <div className='founder-image'>
                                                <img src={process.env.PUBLIC_URL + '/asset/images/about/himanshu-sharma.png'} />
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='mb-5 text-center text-md-start'>
                                                <h3>Himanshu Sharma</h3>
                                                <h4>Founder</h4>
                                            </div>
                                            <p>The visionary behind ARUWA's success in the world of modular furniture. With a passion for design and a keen eye for innovation, Himanshu embarked on a journey to revolutionize the way people create living spaces and transformed them into modular living. His unwavering commitment to quality and his relentless pursuit of excellence has created India’s No.1 Modular Furniture Brand.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item p-0 ">
                            <div className='about-slider item'>
                                <div className='container'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-4'>
                                            <div className='founder-image'>
                                                <img src={process.env.PUBLIC_URL + '/asset/images/about/lovetesh-jain.png'} />
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='mb-5 text-center text-md-start'>
                                                <h3>Lovetesh Jain</h3>
                                                <h4>COO</h4>
                                            </div>
                                            <p>He is the foremost problem solver Aruwa can ask for with mind that runs on logic & data with his practical approach adds positivity & freshness to Aruwa .</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </OwlCarousel>

            <section className='py-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 mb-3 mb-md-0'>
                            <div className='mission-card'>
                                <div className='text-center'>
                                    <h3>Our Vision</h3>
                                    <img src={process.env.PUBLIC_URL + '/asset/images/about/vision.png'} className='img-fluid' />
                                </div>
                                <p>Aruwa’s vision is to create a world where everyone has access to affordable and functional modular furniture that allows them to make a space that they love to live in.</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3 mb-md-0'>
                            <div className='mission-card'>
                                <div className='text-center'>
                                    <h3>Our Mission</h3>
                                    <img src={process.env.PUBLIC_URL + '/asset/images/about/mission.png'} className='img-fluid' />
                                </div>
                                <p>Our mission is to elevate lives through our remarkable modular furniture. Everyone deserves a world where style and functionality harmonize seamlessly.</p>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3 mb-md-0'>
                            <div className='mission-card'>
                                <div className='text-center'>
                                    <h3>Our Purpose</h3>
                                    <img src={process.env.PUBLIC_URL + '/asset/images/about/purpose.png'} className='img-fluid' />
                                </div>
                                <p>Our purpose is to deliver modular interiors that define your unique preferences and reflect functional, aesthetic and safe residential and commercial spaces for a better wellbeing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='py-5 back-cover' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/asset/images/about/back-2.png'})` }}>
                <div className="container" >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="about-img-border">
                                <video
                                    className="about-img  img-fluid "
                                    autoPlay
                                    loop
                                    muted
                                    name="media"
                                >
                                    <source src={AboutVideo} type="video/mp4" />{" "}
                                </video>
                            </div>
                        </div>
                        <div className="col-md-8 pe-5">
                            <h2 className="text-center">Say Hello To Our ARUWA Team </h2>
                            <h5 className="text-center">The brains that make our clients happy!</h5>
                            <p className="text-justify">
                                Aruwa Interiors as a team has pioneered in the interior design industry, with its extravagant designs for built-in modular kitchens, modular wardrobes, furniture and the list goes on. The brand has combined its years of experience in the furnishing industry with innovative ideas to create furnishing ideas that leave customers awe-struck. Aruwa is constantly working on technology optimization to create breathtaking designs at pocket-friendly rates.
                            </p>
                            <div className='text-md-end'>
                            <img src={process.env.PUBLIC_URL + '/asset/images/about/layer-129.png'} className='img-fluid aruwa-team-img' />
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <CityContact/>
        </>
    )
}
export default AboutUs