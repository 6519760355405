import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import customer_layer_1 from "../asset/images/modular-page-assets/customer-layer-1.png";
import customer_layer_2 from "../asset/images/modular-page-assets/customer-layer-2.png";
import config from "../config";
import Spinner from "../components/Spinner/Spinner";

const DesignIdeas = () => {
  const uploadUrl = `${config.base_uploadUrl}post_type_pages/`;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [designIdeasData, setDesignIdeas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${config.base_url}get-design-ideas`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setDesignIdeas(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const handleViewSubCategory = (data) => {
    // {`/blog-detail/${data.slug}
    navigate("/blog/" + data.slug, { state: { blogData: data } });
  };

  return (
    <>
      <Helmet>
        <title>Home Interior Design Ideas | Home Interior Design Styles</title>
        <meta
          name="description"
          content="Aruwa is your ultimate destination for home interior design ideas, inspiration, and trends.
Browse through millions of photos from design professionals and discover how to turn your house into your dream home with us. "
        />
      </Helmet>
      <section className="banner-top text-dark">
        <div className="container py-md-5 p-3">
          <div className="row align-items-start">
            <div className="col-md-12">
              <h1>
                <span className="yellow-text">Home</span> Interior Design Ideas
              </h1>
            </div>
            <div className="col-md-10">
              <h5>
                Our interior design catalogue section features a variety of
                design concepts that we offer. Browse through our interior
                design options and discover how they can enhance your space.
                Choose from the different ideas we have for bedrooms, kitchens,
                puja rooms, living rooms and more.
              </h5>
            </div>
            <div className="col-md-2">
              <div className="top-section-image text-center text-md-start">
                <img
                    alt="desing_banner.png"
                  src={
                    process.env.PUBLIC_URL +
                    "/asset/images/design-ideas/design_banner.png"
                  }
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {error ? (
        <div>Error: {error.message}</div>
      ) : !isLoaded ? (
        <div>
          <Spinner />
        </div>
      ) : (
        designIdeasData.design_ideas.category.map((data, index) => (
          <section
            className={`kitchen-design  py-md-5 p-3 bg-design-1 back-layer-container ${
              index % 2 === 0 ? "gray-background" : ""
            }`}
            key={index}
          >
            {index === 0 && (
              <div className="back-layer-1">
                <img className="img-fluid" src={customer_layer_1} />
              </div>
            )}
            {index === designIdeasData.design_ideas.category.length - 1 && (
              <div className="back-layer-2">
                <img className="img-fluid" src={customer_layer_2} />
              </div>
            )}

            <div className="container">
              <div className="row">
                <div className="col-md-12 mb-4">
                  <h2>{data.category_name}</h2>
                </div>
                {data.sub_categories.map((design_obj, index_second) => (
                  <div className="col-md-4" key={index_second}>
                    <div className="design-card mb-4">
                      <div className="design-card-icon mb-2">
                        <img
                          className="img-fluid"
                          src={uploadUrl + design_obj.featured_image}
                        />
                      </div>
                      <div className="design-card-title text-center">
                        <h4>{design_obj.category_name}</h4>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ))
      )}
      <section className="map-section pt-5" id="scrollSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 p-md-0">
              <iframe
               title=" "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.7130841227827!2d75.77717887543726!3d26.849076576685267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db56b8369f6d1%3A0x93e2863584cbaa1c!2sAruwa%20Interiors%20Jaipur%20%7C%20Best%20Interior%20Designers%20in%20Jaipur%20%7C%20Turnkey%20Projects!5e0!3m2!1sen!2sin!4v1703826390799!5m2!1sen!2sin"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-100 h-100"
              ></iframe>
            </div>
            <div className="col-md-5">
              <form className="banner-form form-with-map">
                <div className="row">
                  <div className="col-12 px-0 px-lg-0 text-center">
                    <h4>You Are One Click Away</h4>
                    <p>
                      Get your <span className="yellow-text">dream home</span>{" "}
                      today. Let our experts help you
                    </p>
                  </div>
                  <div className="col-12 px-0 px-lg-0 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Name*"
                    />
                  </div>
                  <div className="col-12 px-0 px-lg-0 mb-2">
                    <input
                      type="tel"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Mobile Number*"
                    />
                  </div>
                  <div className="col-12 px-0 px-lg-0 mb-2">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Email*"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="col-12 px-0 px-lg-0 mb-2">
                    <textarea
                      className="form-control input-alike"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Your location.."
                    ></textarea>
                  </div>
                  <div className="col-12 px-0 px-lg-0 mb-2">
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Message.."
                    ></textarea>
                  </div>
                  <div className=" col-12 col-md-6 mb-2 form-check">
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      MODULAR KITCHEN
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <div className=" col-12 col-md-6 mb-2 form-check">
                    <label className="form-check-label"htmlFor="exampleCheck1">
                      HOME INTERIOR
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <div className=" col-12 col-md-6 mb-2 form-check">
                    <label className="form-check-label"htmlFor="exampleCheck1">
                      MODULAR FURNITURE
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <div className=" col-12 col-md-6 mb-2 form-check">
                    <label className="form-check-label"htmlFor="exampleCheck1">
                      COMMERCIAL INTERIOR
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <div className=" col-12 col-md-6 mb-2 form-check">
                    <label className="form-check-label"htmlFor="exampleCheck1">
                      OTHERS
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                  </div>
                  <div className="col-8 text-center mx-auto justify-self-center">
                    <button
                      type="submit"
                      className="btn hire-us radius-50 my-3"
                    >
                      SUBMIT NOW
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DesignIdeas;
