import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import config from "../config";
import CareerForm from "../components/CareerForm";
import WhyChoose from "../components/Static-Section/whyChoose";

const Career = () => {
  const sectionRef = useRef(null);
  const sectionOpportunityRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [careerData, setCareer] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);

  const handleButtonClick = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollOpportunityButtonClick = () => {
    sectionOpportunityRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleViewDetails = (index) => {
    // Toggle the expanded state for the clicked item
    setExpandedItem(expandedItem === index ? null : index);
  };

  useEffect(() => {
    fetch(`${config.base_url}career`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCareer(result.career);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const whychoose = {
    'headering_before': 'Perks of Joining our Franchise Program! ',
    'features': [
      {
        'title': 'Growth Opportunities',
        'desc': "ARUWA offers ample opportunities to its employees to polish their skills by offering them diverse projects and providing them with experienced mentorship."
      },
      {
        'title': 'Take Charge',
        'desc': "Be empowered to take ownership of your work and make a real impact by joining a company that values accountability and autonomy."
      },
      {
        'title': 'Create Disruption',
        'desc': "ARUWA believes that curiosity is a fuel for igniting innovation, so we encourage our employees to ask questions and be creative in their work."
      },
      {
        'title': 'Working Benefits',
        'desc': 'Aruwa values dedication and hard work, offering our employees with rewards, performance bonuses, competitive salaries, incentives, and recognition.'
      },
      {
        'title': 'Challenge Yourself',
        'desc': "We believe that growth is a continuous process and project our teams to work on diverse projects that would polish their skills and push them for perfection."
      },
      {
        'title': 'Fun at Work',
        'desc': "Join a vibrant and inclusive workplace where work and fun go hand in hand, creating an environment that sparks creativity and enjoyment."
      },
    ]
  };

  return (
    <>
      <div
        className=" banner-top bg-dark py-5 vh-100"
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/asset/images/banner/career/career-back.png'})` }}
      >
        <div className="container-fluid  h-100">
          <div className="row align-items-center py-5 h-100">
            <div className="col-12 col-xl-6  ps-md-100 text-center text-md-start">
              <h1 className="text-center text-md-start display-3 fw-bold mb-4 text-dark">
                Explore the Oppurtunites <br /> with ARUWA
              </h1>

              <div className="col-md-6 "></div>
            </div>
            <div className="col-12 col-md-6 d-xl-flex position-relative row align-items-md-end h-100  ">
              <img
                src={process.env.PUBLIC_URL + '/asset/images/banner/career/career-banner-icon.png'}
                className="w-100 banner-icon"
                style={{ zIndex: 15 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 mb-3 py-5">
              <h2>Unlock Your Career Potential for Infinite Opportunities</h2>
            </div>
            <div className="col-md-8">
              <p>Are you passionate about interior design and want to build your career as an interior designer?</p>

                <p>If yes, then we have the perfect opportunity for you.</p>

                <p>ARUWA is one of the fastest-growing interior design companies in India that is on a mission to create beautiful, functional, and sustainable spaces for clients.</p>

                <p>We have a wide range of services, from online design consultation to 3D space-planning, and delivering our exceptional design ideas according to different budgets and preferences.</p>

                <p>We have successfully completed over 10,000 projects across 50 cities by giving high-quality results and exceeding customer expectations.
              </p>
            </div>
            <div className="col-md-4">
              <img src={process.env.PUBLIC_URL + '/asset/images/run-to-aruwa.png'} className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section ref={sectionOpportunityRef} className=" py-md-5 py-3">
        <div className="container">
          <div className="row">
            <div className=" col-12 mb-md-4 text-center">
              <h2>
              Find Your Dream Job At <span className="yellow-text">ARUWA</span>
              </h2>
              <Link className="btn btn-outline-warning rounded-50 py-3 px-5">Current Openings</Link>
            </div>
          </div>
          <div className="row m-2">
            {careerData.map((data, index) => {

              return (
                <div
                  className="col-md-12 shadow-left-bottom p-3 p-md-4 mb-3"
                  key={data.title}
                >
                  <div className="row">
                    <div className="col-12 col-md-9 mb-3 mb-md-0">
                      <div className="row">
                        <div className="col-12 mb-3 text-center text-md-start mb-md-4">
                          <h5>{data.title}</h5>
                        </div>
                        <div className="col-12 text-md-start text-center ">
                          <a className="btn btn-outline-dark radius-50 px-3 py-0 me-1 mb-1">
                            {data.experience}
                          </a>
                          <a className="btn btn-outline-dark radius-50 px-3 py-0 me-1 mb-1">
                            {data.position}
                          </a>
                          <a className="btn btn-outline-dark radius-50 px-3 py-0 me-1 mb-1">
                            {data.location}
                          </a>
                        </div>
                        {expandedItem === index && (
                          <div className="py-2 col-12">
                            <div dangerouslySetInnerHTML={{ __html: data.job_description }} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-3 text-center text-md-end">
                      <button
                        onClick={() => handleViewDetails(index)}
                        className="btn  btn-outline-dark text-capitalize px-md-5 py-1 py-md-2 rounded-0 mb-md-2 me-md-0 me-2 "
                      >
                        View Details
                      </button>
                      <button
                        onClick={handleButtonClick}
                        className="btn-yellow text-capitalize px-md-5 py-1 py-md-2 rounded-0"
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

            <WhyChoose data={whychoose}/>

      <section ref={sectionRef} className="bg-light-yellow py-md-5 pt-3">
        <div className="container">
          <div className="row justify-content-center justify-content-md-start px-3 px-md-5 py-4">
            <div className="col-md-12 mb-4 text-center">
              <h3 className="display-5 fw-bold mb-md-4">
                Submit Your Application
              </h3>
              <h5>Fill the form below in the required format to apply </h5>
            </div>
            <CareerForm></CareerForm>
          </div>
        </div>
      </section>
    </>
  );
};

export default Career;
