
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import FullHomeInteriors from "./pages/Services/FullHomeInteriors";
import MoudlarInteriros from "./pages/Services/ModularInterirors";
import CommercialInteriors from "./pages/Services/CommercialInteriors";
import Renovation from "./pages/Services/RenovationNew";
import "@fortawesome/fontawesome-free/css/all.css";
import Blogs from "./pages/Blogs/Blogs";
import ContactTwo from "./pages/Contact_2";
import BecomeFranchisee from "./pages/JoinUs/BecomeFranchisee";
import ModularKitcheJaipur from "./pages/Cites/ModularKitchenJaipur";
import TopRatedInteriorJaipur from "./pages/Cites/TopRatedInteriorJaipur";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import Career from "./pages/Career";
import DesignIdeas from "./pages/DesignIdeas";
import Blog from "./pages/Blogs/Blog";
import ScrollToTop from "./components/ScrollToTop";
import Cost from "./pages/Cost";
import React from "react";
import FeedBackForm from "./pages/FeedBackForm";
import NotFound from "./pages/NotFound";
import TopRatedInteriorAlwar from "./pages/Cites/alwar/TopRatedInteriorAlwar";
import ThankYou from "./pages/ThankYou";
import ModularKitcheAlwar from "./pages/Cites/alwar/ModularKitcheAlwar";
import Franchise from './pages/Franchise';

function App() {
  return (
    <BrowserRouter   >
      <ScrollToTop />
      <Routes>
        <Route path={"/"} element={<Nav />}>
          <Route index element={<Home />} />
          <Route path="/full-home-interiors" element={<FullHomeInteriors />} />
          <Route path="/renovation-services-india" element={<Renovation />} />
          <Route path="/modular-interior-solution" element={<MoudlarInteriros />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route
            path="/commercial-interior"
            element={<CommercialInteriors />}
          />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact-us" element={<ContactTwo />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route
            path="/modular-kitchen-jaipur"
            element={<ModularKitcheJaipur />}
          />
          <Route
            path="/top-rated-interior-jaipur"
            element={<TopRatedInteriorJaipur />}
          />
          <Route
            path="/interior-designers-in-alwar"
            element={<TopRatedInteriorAlwar />}
          />
          <Route
            path="/modular-kitchen-in-alwar"
            element={<ModularKitcheAlwar />}
          />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
          <Route path="/career" element={<Career />} />
          <Route path="/home-interior-design-ideas" element={<DesignIdeas />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/cost" element={<Cost />} />
          <Route path="/feedback-form" element={<FeedBackForm />} />
          <Route path="/thankyou" element={<ThankYou />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
