import React, { useState }  from 'react';

const TransformSpace = () => {
    const [activeData, setActiveData] = useState("Data 1");
    const categorySlug = 'full-home-interiors';
    const handleButtonClick = (data) => {
      setActiveData(data);
    };
    return (
        <>
            <section className="container py-md-5 py-3">
                <div className="row justify-content-center mb-md-5">
                    <div className="col-md-12 text-center mb-4">
                        <h2 className=" fw-bold">
                        Transform Your Space in Four Easy Steps
                        </h2>
                        {/* <p>Creating the space of your dreams</p> */}
                    </div>
                    <div className='col-lg-5 text-center'>
                        <img src='../asset/images/transform.png' className='img-fluid transform-section-image' />
                    </div>
                    <div className="col-lg-7">
                    <div className='transform-container'>
                        <img src='../asset/images/full_home_interior/transform/plant.png' className='img-fluid plant-icon'/>
                        <div
                            className="transform-steps aos-init aos-animate justify-content-center"
                            data-aos="fade-up"
                        >
                            <div
                                className={`transform-step`}
                                onClick={() => handleButtonClick("Data 1")}
                            >
                                <div
                                     className={`transform-content ${activeData === "Data 1" ? "active" : ""
                                            }`}
                                    data-toggle="popover"
                                    data-trigger="hover"
                                    data-placement="top"
                                    title=""
                                    data-content="And here's some amazing content. It's very engaging. Right?"
                                    data-original-title="2003"
                                >
                                    <p
                                        className={`my-2 ${activeData === "Data 1" ? "text-black" : ""
                                            }`}
                                    >
                                        1.
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`transform-step `}
                                onClick={() => handleButtonClick("Data 2")}
                            >
                                <div
                                     className={`transform-content ${activeData === "Data 2" ? "active" : ""
                                            }`}
                                    data-toggle="popover"
                                    data-trigger="hover"
                                    data-placement="top"
                                    title=""
                                    data-content="And here's some amazing content. It's very engaging. Right?"
                                    data-original-title="2004"
                                >
                                    <p
                                        className={`my-2 ${activeData === "Data 2" ? "text-black" : ""
                                            }`}
                                    >
                                        2.
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`transform-step `}
                                onClick={() => handleButtonClick("Data 3")}
                            >
                                <div
                                     className={`transform-content ${activeData === "Data 3" ? "active" : ""
                                            }`}
                                    data-toggle="popover"
                                    data-trigger="hover"
                                    data-placement="top"
                                    title=""
                                    data-content="And here's some amazing content. It's very engaging. Right?"
                                    data-original-title="2005"
                                >
                                    <p
                                        className={`my-2 ${activeData === "Data 3" ? "text-black" : ""
                                            }`}
                                    >
                                       3.
                                    </p>
                                </div>
                            </div>
                            <div
                                className={`transform-step `}
                                onClick={() => handleButtonClick("Data 4")}
                            >
                                <div
                                     className={`transform-content ${activeData === "Data 4" ? "active" : ""
                                            }`}
                                    data-toggle="popover"
                                    data-trigger="hover"
                                    data-placement="top"
                                    title=""
                                    data-content="And here's some amazing content. It's very engaging. Right?"
                                    data-original-title="2010"
                                >
                                    <p
                                        className={`my-2 ${activeData === "Data 4" ? "text-black" : ""
                                            }`}
                                    >
                                       4.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {activeData && (
                    <div>
                        {activeData === "Data 1" && (
                            <div className="transform-box">
                                <section className=" py-md-4 py-3">
                                <div class="card transform-card" >
                                    <img className='card-img-top img-fluid' src='../asset/images/full_home_interior/transform/transform-1.png'/>
                                    <div class="card-body">
                                        <h5 class="card-title">Explore</h5>
                                        <p class="card-text">Meet our designer, discuss inventive ideas and start designing by paying off 5% of the final quote, or ₹ 21,000 (whichever is higher). </p>
                                    </div>
                                </div>
                                </section>
                            </div>
                        )}
                        {activeData === "Data 2" && (
                            <div className="transform-box">
                                <section className=" py-md-4 py-3">
                                <div class="card transform-card" >
                                    <img className='card-img-top img-fluid' src='../asset/images/full_home_interior/transform/transform-2.png'/>
                                    <div class="card-body">
                                        <h5 class="card-title">Place the Order</h5>
                                        <p class="card-text">Place the order by paying 60% of your project cost to expedite the order process.</p>
                                    </div>
                                </div>
                                </section>
                            </div>
                        )}
                        {activeData === "Data 3" && (
                            <div className="transform-box">
                                <section className=" py-md-4 py-3">
                                <div class="card transform-card" >
                                    <img className='card-img-top img-fluid' src='../asset/images/full_home_interior/transform/transform-3.png'/>
                                    <div class="card-body">
                                        <h5 class="card-title">Begin Installation</h5>
                                        <p class="card-text">Begin installation by paying 100% of the amount before delivery and see your dreams coming alive.</p>
                                    </div>
                                </div>
                                </section>
                            </div>
                        )}
                        {activeData === "Data 4" && (
                            <div className="transform-box">
                                <section className=" py-md-4 py-3">
                                <div class="card transform-card" >
                                    <img className='card-img-top img-fluid' src='../asset/images/full_home_interior/transform/transform-4.png'/>
                                    <div class="card-body">
                                        <h5 class="card-title">Move-in</h5>
                                        <p class="card-text">Now is the time for you to experience the comfort at Aruwa Spaces.</p>
                                    </div>
                                </div>
                                </section>
                            </div>
                        )}
                    </div>
                )}
                    </div>
                    </div>

                </div>
                
            </section>
        </>
    );
}

export default TransformSpace;