import React, { useState  ,useEffect} from "react";
import config from "../config";
const CareerForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    position: "",
    resume: null,
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage(null);
    try {
  

      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("mobile", formData.mobile);
      formDataToSend.append("position", formData.position);
      formDataToSend.append("resume", formData.resume);
      formDataToSend.append("message", formData.message);

      const response = await fetch(`${config.base_url}career-form`, {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setSuccessMessage("Form submitted successfully");
        setFormData({
          name: "",
          email: "",
          mobile: "",
          position: "",
          resume: null,
          message: "",
        });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Form submission failed:", error);
    }

    setIsLoading(false);
  };



  const [careerPostions, setPostions] = useState([]);

  useEffect(() => {
    fetch(`${config.base_url}career-positions`)
      .then((res) => res.json())
      .then(
        (result) => {
    
          setPostions(result.positions); 
        },
        (error) => {
      
        }
      );
  }, []);

  return (
    <>
      <form  onSubmit={handleSubmit} className="career-form">
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}
        <div className="row justify-content-md-end justify-content-center">
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="fullname" className="form-label">
              Full Name*
            </label>
            <input
              type="text"
              id="fullname"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="text-1" className="form-label">
              Email*
            </label>
            <input
              type="email"
              id="text-1"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="text-1" className="form-label">
              Mobile*
            </label>
            <input
             pattern="[0-9]+"
             title="enter only number"
             maxLength={10}
             minLength={10}
              type="text"
              id="text-1"
              className="form-control"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="position" className="form-label">
                Position you are applying for
            </label>
            <select
                id="position"
                className="form-select"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
                required
            >
                <option value="">Select Position</option>
                {careerPostions.map((data) => {
                                    return(
                <option value={data.name}>{data.name}</option>
                );
               })} 
            </select>
          </div>
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="resume" className="form-label">
              Upload Resume
            </label>
            <input
              type="file"
              id="resume"
              className="form-control"
              placeholder="Upload Resume"
              accept="application/pdf"
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  resume: e.target.files[0],
                }))
              }
            />
          </div>
          <div className="mb-3 col-12 col-md-6">
            <label htmlFor="text-1" className="form-label">
              Message
            </label>
            <input
              type="text"
              id="text-1"
              className="form-control"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-12 mt-3 mt-md-5 mb-3 text-right text-md-center">
            <button
              type="submit"
              className="btn hire-us radius-50 my-3 banner-button undefined submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default CareerForm;

