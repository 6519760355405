import Path54379 from '../../asset/images/modular-kitchen/Path 54379.svg'
import Faq2 from '../../components/Faq2';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import BannerSlider from '../../components/BannerSlider';
import Counter_section from '../../components/Static-Section/Counter-section';
import BasicGrid from "../../components/Static-Section/BasicGrid";
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-1.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-2.png'
import WhyChoose from "../../components/Static-Section/whyChoose";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Calculate_now from '../../components/Static-Section/Calculate_section';
import Icons8Bill64 from "../../asset/images/icons/service-1/icons8-bill-64.png";
import Icons8Bill96 from "../../asset/images/icons/service-1/icons8-comments-96.png";
import Showroom2 from "../../asset/images/icons/service-1/showroom-(2).png";
import Icons8MoneyTransfer96 from "../../asset/images/icons/service-1/icons8-money-transfer-96 (1).png";
import Icons8StudioFloorPlan48 from "../../asset/images/icons/service-1/icons8-studio-floor-plan-48.png";
import Icons8CallMale100 from "../../asset/images/icons/service-1/icons8-call-male-100.png";
import Value_section from '../../components/Static-Section/Values_Section';
import Icons8FillinForm96 from "../../asset/images/icons/service-1/icons8-fill-in-form-96.png";
import CustomerSection from '../../components/Static-Section/CustomerSection';
import CityContact from '../../components/CityContact';
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'
import TransformSpace from '../../components/Static-Section/TransformSpace';


const TopRatedInteriorJaipur = () => {




  const options = {
    items: 1, // Number of items to show
    loop: true, // Enable looping
    autoplay: true, // Enable autoplay
    autoplaySpeed: 500, // Autoplay speed in milliseconds
  };
  const [activeData, setActiveData] = useState("Data 1");
  const categorySlug = "full-home-interiors";
  const handleButtonClick = (data) => {
    setActiveData(data);
  };


  const slides = [
    {
      'id': 1,
      'title': 'Top-Quality Interior Designer in Jaipur, Rajasthan',
      'sub_title': "Aruwa is the most reputed interior design company for homes in Jaipur, creating beautiful and functional spaces that enhance your living style and aesthetics.",
      'background': '../asset/images/banner/top-designer/top-designer-1.png'
    },
    {
      'id': 2,
      'noneh_title': 'Top-Quality Interior Designer in Jaipur, Rajasthan',
      'sub_title': "Aruwa is the most reputed interior design company for homes in Jaipur, creating beautiful and functional spaces that enhance your living style and aesthetics.",
      'background': '../asset/images/banner/top-designer/top-designer-2.png'
    },
  ];

  const BasicGrid_content = [
    {
      'id': 1,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/living_room.png',
      'grid_title': 'Living Room'
    },
    {
      'id': 2,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/Island_Modular_Kitchen.png',
      'grid_title': 'Modular Kitchen'
    },
    {
      'id': 3,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/modular-design/wardrobes.png',
      'grid_title': 'Wardrobe'
    },
    {
      'id': 4,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/pooja-room.png',
      'grid_title': 'Pooja Room'
    },
    {
      'id': 5,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/master-bedroom.png',
      'grid_title': ' Master Bedroom'
    },
    {
      'id': 6,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/kid-bedroom.png',
      'grid_title': "Kid's Bredroom"
    },
    {
      'id': 7,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/dining-room.png',
      'grid_title': 'Dining Room'
    },
    {
      'id': 8,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/bathroom.png',
      'grid_title': ' Bathroom'
    },
    {
      'id': 8,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/corporate_offices.png',
      'grid_title': ' Office Space'
    },
  ];

  const whychoose = {
    'headering_before': 'Why Should You Prefer Aruwa as Your ',
    'heading_yellow': 'Interior Designer ',
    'heading_after': 'in Jaipur  ',
    'subtitle': 'Opting Aruwa as your interior designer company in Jaipur is a choice you will always appreciate as we take immense pride in our exceptional artistry. We assure that every aspect of the home interior is conceptualized and executed to perfection.',
    'features': [
      {
        'title': 'Innovative Modular Furniture',
        'desc': "We at Aruwa believe in redefining spaces with our innovative modular furniture and interior solutions to transform them into a living marvel."
      },
      {
        'title': 'Customizable & Versatile Designs',
        'desc': "At Aruwa, we create customized and versatile designs using 3D visualization techniques to impart design perfection to the spaces."
      },
      {
        'title': 'Proficiency in Designing Spaces',
        'desc': "We offer a wide range of interior designer services in Jaipur, crafting spaces for elegance and innovation with skilled proficiency."
      },
      {
        'title': 'Architectural Consultation',
        'desc': 'We provide exceptional architectural consultation services to customers that aids in attaining maximum utilisation from a space.'
      },
      {
        'title': 'Careful Attention to Detail',
        'desc': "At Aruwa we pay close attention to detail and ensure that our customer’s experience is an exceptional one from start to the end."
      },
      {
        'title': 'Seamless Project Management',
        'desc': "Aruwa offers effective project management to ensure that the customer’s project is completed on committed time and within the intended budget."
      },
    ]
  };


   const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How do you communicate project updates and progress?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We communicate the progress of the project by sending regular updates to our customers via emails, calls and meetings, whichever suits the best interest of the customer. We conduct visual presentation sessions for our customers to explain to them the whole process of design execution."
      }
    },{
      "@type": "Question",
      "name": "Are there any additional costs that I should be aware of?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "No, there are no additional costs involved in the final services. We keep our services transparent."
      }
    },{
      "@type": "Question",
      "name": "How do you charge for your interior design services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "You don’t have to worry about the charges as our sales executive support team will reach out to you and will discuss all the details."
      }
    },{
      "@type": "Question",
      "name": "How do I choose the best interior designer in Jaipur for my home?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Aruwa offers the best home interior design services in Jaipur. We have a self-manufacturing unit that enables us to have a leading edge to provide customers with customised modular interior solutions."
      }
    }]
  }
  

  return (
    <>
      <Helmet>
        <title>
          Interior Designer in Jaipur | Interior Decorator for Home in Jaipur
        </title>
        <meta
          name="description"
          content="ARUWA is the best interior designer in Jaipur. 
Contact us for a free consultation and quote. 
We have the perfect interior decorator for your home. "
        />
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <BannerSlider slides={slides} />
      <section className="banner-content-mb">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className=' text-center py-3 px-2'>
                <div className=' px-md-5'>
                  <div className='banner-title h1 fw-bolder'>Top-Quality Interior Designer in Jaipur, Rajasthan</div>
                  <p className='banner-subtitle mb-0'>Aruwa is the most reputed interior design company for homes in Jaipur, creating beautiful and functional spaces that enhance your living style and aesthetics.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-md-none" id='section-city-1' >
        <CityContact />
      </div>

      <Counter_section />

      <section className=" py-md-5 py-3 text-dark bg-white back-layer-container">
        <div className='back-layer-1 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_1} />
        </div>
        <div className='back-layer-2 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_2} />
        </div>
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-12 mb-4 text-black text-center">
              <h2 className="fw-bold mb-md-4">
                Diverse <span className="yellow-text">Interior Design</span> Solutions We Provide
              </h2>
              <p>As the top choice for interior decorators in Jaipur, Aruwa is dedicated to deliver exceptional design solutions to elevate the aesthetics of your space while simultaneously imparting ergonomic functionality to it.
              </p>
            </div>
            <BasicGrid grid_items={BasicGrid_content} small={6} last_full={true}></BasicGrid>
          </div>
        </div>
      </section>

      <WhyChoose data={whychoose} />

      <Aruwa_edge />

      <Calculate_now page_title={'Get Estimates for Your Interior Needs'} />



      <TransformSpace />

      <Value_section />

      <CustomerSection />

      <div className="d-none d-md-block" id='section-city-2'>
        <CityContact /> 
      </div>
      <section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
        <div className="test-layer-1">
          <img src={test_item_1} className="img-fluid" />
        </div>
        <div className="test-layer-2">
          <img src={test_item_2} className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
              <Faq2 categorySlug={"top-rated-interior-jaipur"} ></Faq2>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default TopRatedInteriorJaipur;