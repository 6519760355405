import React from 'react';
import why_us_back from '../../asset/images/modular-page-assets/why-us-back.jpg'


const whyChoose = (data) => {
   const choose = data.data;
    return (
        <>
            <section className="why-us-section py-5 p-md-5 back-cover" style={{ backgroundImage: `url(${why_us_back})` }}>
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-md-12 text-center">
                            <h2>{choose.headering_before} <span className="yellow-text no-black">{choose.heading_yellow}</span>{choose.heading_after}</h2>
                            <h5>{choose.subtitle}</h5>
                        </div>
                    </div>
                    <div className="row mx-md-5">
                        <div className="col-md-12">
                            <div className="why-choose-wrapper">
                            {
                                choose.features.map((e) => (
                                        <div className="why-choose">
                                            <h4>{e.title}</h4>
                                            <p>{e.desc}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default whyChoose;