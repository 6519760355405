export default  {
    STORE_KEY: 'a56z0fzrNpl^2',
    // base_url: 'http://localhost/aruwa-backend/api/',
    // base_uploadUrl:"http://localhost/aruwa-backend/uploads/",

    base_uploadUrl:"https://aruwa.in/aruwa-backend/uploads/",
    base_url: 'https://aruwa.in/aruwa-backend/api/',
  
    COLOR: {
      ORANGE: '#C50',
      DARKBLUE: '#0F3274',
      LIGHTBLUE: '#6EA8DA',
      DARKGRAY: '#999',
    },
  };