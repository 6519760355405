import { Link } from 'react-router-dom';
const Cost = () => {
    return(
        <>
            <div className=" banner-top bg-dark vh-100">
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                        <div className="col-12 col-md-6 my-5 text-center text-md-left">
                            <h2 className="text-center text-md-left display-5 fw-bold mb-4">Get the cost of your full home interiors now
                            </h2>
                            <p className="text-center text-md-left mb-4">Step into the journey of your home interior with certainty &
                                trust of Aruwa</p>
                            <a  className="btn btn-outline-warning rounded-0 py-3 px-5">Calculate Now</a>
                            <div className="col-md-6 ">

                            </div>
                        </div>
                        <div className="col-6 d-none d-md-flex row position-relative align-items-md-end h-100  ">
                            <img src="/../../asset/images/cost/Ellipse 548.svg" className="w-50 position-absolute" style={{zIndex:1,top:'10%',left:'25%'}} alt=""/>
                            <img src="/../../asset/images/cost/hangover-guy.png" className="w-100" style={{zIndex:15}} alt=""/>
                        </div>

                    </div>
                </div>
            </div>

            <section className=" py-md-5 pb-3">
                <div className="container radius-top background-gradient-gray p-3 p-md-5">
                    <div className="row justify-content-start align-items-center ">
                        <div className=" col-12  mb-4">
                            <span className="mb-4"><b>How this works</b></span>
                            <div className="heading-line"></div>
                            <h3 className="display-5 fw-bold mb-md-4">Calculate your home interior cost</h3>
                        </div>
                    </div>
                    <form action="">
                        <div className="row">
                            <div className="col-12 col-md-4 form-group">
                                <h6 htmlFor="exampleInputPassword1">Select Your City</h6>
                                <select className="form-select" aria-label="Default select example">
                                    <option value="1" selected>Jaipur</option>
                                    <option value="2">Mansarovar</option>
                                    <option value="3">Vaishali Nagar</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-4 form-group">
                                <h6 htmlFor="exampleInputPassword1">House type</h6>
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>3 BHK</option>
                                    <option value="1">2 BHK</option>
                                    <option value="2">1 BHK</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-4 form-group">
                                <h6 htmlFor="exampleInputPassword1">Select the size</h6>
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>1600 sqft.</option>
                                    <option value="1">2000 sqft.</option>
                                    <option value="2">3000 sq ft.</option>
                                </select>
                            </div>
                            <div className="col-12 mt-3 mt-md-5 mb-3 text-left text-md-center">
                                <button type="submit"
                                    className="btn-yellow text-capitalize px-md-5 py-2 py-md-2 rounded-0 align-self-center">Get Price
                                    Estimate </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <section className=" py-md-5 py-3">
                <div className="container">
                    <div className="row justify-content-start align-items-center">
                        <div className=" col-12 col-md-8 mb-4">
                            <span className="my-3 "><b>Faq's</b></span>
                            <div className="heading-line"></div>
                            <h3 className="display-5 fw-bold">Sharing Our Knowledge and Passion for Interiors</h3>
                        </div>
                        <div className="col-12 col-md-4 text-center text-md-end">
                            <Link to='/contact-us' className="btn  btn-outline-dark text-capitalize px-md-5 my-3 rounded-0">Contact us</Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="accordion">
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        What is Aruwa?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                            mollit anim id est laborum
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        What is the best material for a modular kitchen?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        How do I buy a modular kitchen?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Having a modular kitchen is a great way to add extra space to your kitchen. The first
                                            step is to have a complete understanding of the available space. Your kitchen triangle
                                            concept should be considered so that you can maximize movement between the stove, sink,
                                            and refrigerator. It's important to plan your electrical fixtures smartly, depending on
                                            the appliances you will need. Lastly, make the best use of available space with
                                            organizers for your household items.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        What services do you offer? Why should I choose them?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        What does a modular kitchen cost?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        Does Aruwa provide readymade interior solutions?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <div className="accordion-item-header">
                                        Do you charge a design fee?
                                    </div>
                                    <div className="accordion-item-body">
                                        <div className="accordion-item-body-content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                                            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container-fluid">
                <div className="row bg-color-gray">
                    <div className="col-md-4 p-0 position-relative d-none d-md-block text-center">
                        <div className="h-100 w-100 position-absolute bg-yellow"
                            style={{zIndex:1,clipPath:"polygon(0 0%, 100% 0%, 101% 0%, 100% 100%) !important"}}></div>
                        <img src="/../../asset/images/cost/Mask Group 63.png" alt="" className="position-relative" style={{zIndex:10}}/>
                    </div>
                    <div className="col-md-8 px-md-1 py-md-3 p-4 bg-yellow" style={{clipPath:"polygon(0 0, 100% 0, 100% 100%, 0 100%)"}}>
                        <div className=" h-100">
                            <h3 className="display-5 fw-bold mb-4">Calculate the estimate cost of your interior now</h3>
                            <a  className="btn  btn-outline-dark text-capitalize px-md-5 py-md-3 rounded-0">Get Price Quote</a>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Cost;