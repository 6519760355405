import React, { useState } from "react";
import config from "../config";

const  FeedBackForm = () =>  {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
      });
      const [successMessage, setSuccessMessage] = useState('');
      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSuccessMessage(null);
        fetch(`${config.base_url}feedback-store`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
        
          .then((response) => {
            // Check if the request was successful
            if (response.ok) {
                setSuccessMessage('Form submitted successfully');
                setFormData({
                    name: '',     
                    email: '',
                    message: ''
                  });
            } else {
              throw new Error("Form submission failed");
            }
          })
          .catch((error) => {
            console.error("Form submission failed:", error);
          })
          .finally(() => {
            setIsLoading(false); // Reset the loading state regardless of success or failure
          });
      };
    return <>
    <section className="top-section">
        <div className="container ">
            <div className="row justify-content-center">
                <div className="col-8 mb-3">
                    <h2>Submit Feedback or Raise a Concern</h2>
                    <p>If you are facing any issues with our services or just want to share your feedback with us. Please fill the form below, we will reach out to you.</p>
                </div>
                <div className="col-8">
    <form className="feedback-form contact-form" onSubmit={handleSubmit}>
                 {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                 
                    <div className="row justify-content-md-start justify-content-center">
                      <div className="mb-3 col-12">
                        <input
                          type="text"
                          id="name"
                          className="form-control text-black border-dark rounded-0"
                          placeholder="Full Name*"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                   
                      <div className="mb-3 col-12">
                        <input
                          type="email"
                          id="email"
                          className="form-control text-black border-dark rounded-0"
                          placeholder="Email*"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                
                      <div className="mb-3 col-12">
                        <textarea
                          name="message"
                          className="border-dark w-100"
                          id="message"
                          placeholder="Message*"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        ></textarea>
                      </div>
                      <div className="col-3 mt-3 mt-md-5 mb-3">
                      <button
                          type="submit"
                          className="btn-dark text-white text-capitalize px-md-5 py-1 py-md-2 rounded-0"
                          disabled={isLoading}
                        >
                          {isLoading ? 'Sending...' : 'Submit'}
                        </button>
                      </div>
                    </div>
                  </form>
                  </div>
            </div>
        </div>
    </section>
    </>
}
export default FeedBackForm