import Faq2 from "../../components/Faq2";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import BannerSlider from "../../components/BannerSlider";
import why_us_back from '../../asset/images/modular-page-assets/why-us-back.jpg'
import config from "../../config";
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'
import form_image from '../../asset/images/modular-page-assets/form-image.jpg'
import Counter_section from "../../components/Static-Section/Counter-section";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Value_section from "../../components/Static-Section/Values_Section";
import Calculate_now from "../../components/Static-Section/Calculate_section";
import CustomerSection from "../../components/Static-Section/CustomerSection";
import TimelineContent from "../../components/TimelineContent";
import BasicGrid from "../../components/Static-Section/BasicGrid";
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-1.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-2.png'

import background_renovation_1 from '../../asset/images/banner-slider/renovation/renovation_banner1.png';
import background_renovation_2 from '../../asset/images/banner-slider/renovation/renovation_banner2.png';
import CityContact from "../../components/CityContact";


const Renovation = () => {
    const [activeData, setActiveData] = useState("Data 1");

    const handleButtonClick = (data) => {
        setActiveData(data);
    };

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        city: "Jaipur",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSuccessMessage(null);
        fetch(`${config.base_url}contact-us`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                // Check if the request was successful
                if (response.ok) {
                    setSuccessMessage("Form submitted successfully");
                    setFormData({
                        name: "",
                        mobile: "",
                        email: "",
                        city: "Jaipur",
                        message: "",
                    });
                } else {
                    throw new Error("Form submission failed");
                }
            })
            .catch((error) => {
                console.error("Form submission failed:", error);
            })
            .finally(() => {
                setIsLoading(false); // Reset the loading state regardless of success or failure
            });
    };



    const [activeImage, setActiveImage] = useState(null);

    const handleImageHover = (imageNumber) => {
        setActiveImage(imageNumber);
    };

    const handleImageClick = (imageNumber) => {
        // Optionally, you can add specific logic for click events here.
        setActiveImage(imageNumber);
    };

    const BasicGrid_content = [
        {
            'id': 1,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_1.jpg',
            'grid_title': 'Living Room Renovation'
        },
        {
            'id': 2,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_2.jpg',
            'grid_title': 'Modular Kitchen Remodeling'
        },
        {
            'id': 3,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_3.jpg',
            'grid_title': 'Wardrobe Remodeling'
        },
        {
            'id': 4,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_4.jpg',
            'grid_title': 'Bedroom Renovation'
        },
        {
            'id': 5,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_5.jpg',
            'grid_title': 'Modular Furniture Remodeling'
        },
        {
            'id': 6,
            'grid_image_url': '/asset/images/renovationGrid/reno_grid_6.jpg',
            'grid_title': 'Design & Consultancy'
        },
    ];


    const slides = [
        {
            'id': 1,
            'title': 'Best Renovation Services in India for Your Dream Home',
            'sub_title': "Aruwa is a top-notch home renovation service provider in India that caters to all home remodeling requirements under one roof ",
            'background': background_renovation_1
        },
        {
            'id': 2,
            'h2_title': 'Best Renovation Services in India for Your Dream Home',
            'sub_title': "Aruwa is a top-notch home renovation service provider in India that caters to all home remodeling requirements under one roof ",
            'background': background_renovation_2
        },
    ]
    return (
        <>
            <main className="sans-serif-font">
                <Helmet>
                    <title>
                        Renovation Services in India | Home Remodeling Services India
                    </title>
                    <meta
                        name="description"
                        content="We offer the best home renovation and remodeling services in India.
We offer quality, professional, and personalized services for your home.
Contact us for a free consultation and quote."
                    />
                </Helmet>

                <section>
                    <div className="container-fluid ">
                        <div className="row ">
                            <BannerSlider slides={slides}></BannerSlider>
                        </div>
                    </div>
                </section>

                <section className="banner-content-mb">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>Best Renovation Services in India for Your Dream Home</h1>
                                        <p className='banner-subtitle mb-0'>Aruwa is a top-notch home renovation service provider in India that caters to all home remodeling requirements under one roof </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Counter_section></Counter_section>

                {/* basic grid design */}
                <section className=" py-md-5 py-3 text-dark bg-white back-layer-container">
                    <div className='back-layer-1'>
                        <img className='img-fluid' src={customer_layer_1} />
                    </div>
                    <div className='back-layer-2'>
                        <img className='img-fluid' src={customer_layer_2} />
                    </div>
                    <div className="container">
                        <div className="row justify-content-center justify-content-md-start">
                            <div className="col-md-12 mb-4 text-black text-center">
                                <h2 className="fw-bold mb-md-4">
                                    Delivering Unique and Customized <span className="yellow-text">Home Renovation</span> Solutions
                                </h2>
                                <p>We at Aruwa aim to provide comprehensive home remodeling services that are customized as per customer’s needs and preferences. The designs for renovation are prepared to enhance the aesthetic appeal and practicality of homes.</p>
                            </div>
                            <BasicGrid grid_items={BasicGrid_content} small={6}></BasicGrid>
                        </div>
                    </div>
                </section>

                <section className="why-us-section py-5 p-md-5 back-cover" style={{ backgroundImage: `url(${why_us_back})` }}>
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-12 text-center">
                                <h2>What Make Aruwa's <span className="yellow-text no-black">Home Remodelling </span>Services Unique?</h2>
                                <p>Our home remodeling services are crafted in such a way that they are budget-friendly and at the same time the designs radiate aesthetic appeal.</p>
                            </div>
                        </div>
                        <div className="row mx-md-5">
                            <div className="col-md-12">
                                <div className="why-choose-wrapper">
                                    <div className="why-choose">
                                        <h4>Enhanced Comfort and Functionality</h4>
                                        <p>Our home renovation services enhance the living experiences of our customers by creating innovative designs that are aesthetically pleasing. </p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Increased Energy Efficiency</h4>
                                        <p>At Aruwa, we believe in creating homes that are energy-efficient by incorporating technologies that help reducing energy consumption.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Increased Home Value</h4>
                                        <p>Choosing Aruwa's home renovation services ensures that the value of your home increases in terms of elegance and ornamentation.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Reduced Maintenance Costs</h4>
                                        <p>The spaces renovated by Aruwa require minimum maintenance as we incorporate only high-quality and durable materials.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Modernized Design</h4>
                                        <p>We include latest design trends to ensure that the renovated space reflects a modern and stylish appearance.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Personal Satisfaction</h4>
                                        <p>We believe in customer satisfaction and deliver our remodeling services to create an environment that reflects the customer’s essence and lifestyle.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Aruwa_edge></Aruwa_edge>


                <Value_section></Value_section>

                <TimelineContent></TimelineContent>

                <CustomerSection></CustomerSection>

                <CityContact/>

                <section className=" py-md-5 py-5 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
                    <div className="test-layer-1">
                        <img src={test_item_1} className="img-fluid" />
                    </div>
                    <div className="test-layer-2">
                        <img src={test_item_2} className="img-fluid" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
                                <Faq2 categorySlug={"renovation-services-india"} ></Faq2>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default Renovation;
