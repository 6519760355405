import React from 'react';

function Aruwa_edge(){
    return(
        <>
            <section className="aruwa-edge py-1 p-md-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="mb-3 text-center dark-brown-text">The <span className="yellow-text">ARUWA</span> Edge</h2>
                            <div className="table-responsive">
                                <table className="aruwa-edge-table table table-bordered ">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">ARUWA Experience</th>
                                            <th scope="col">Typical Experience</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">PRICE</th>
                                            <td>No hidden costs<br />
                                                Price Match Guarantee<sup>2</sup></td>
                                            <td>45% hike between first quote and final cost</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">ACCESSIBILITY</th>
                                            <td>A collective unit for all interior solutions</td>
                                            <td>Switching shops from one to another.</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">DESIGN</th>
                                            <td>3D visual with customised design</td>
                                            <td>2D design visual cut-outs</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">DURATION</th>
                                            <td>45-day installation<br /> Step by step update </td>
                                            <td>Non reliable <br />
                                                Delays and weak updates
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">QUALITY</th>
                                            <td>High-quality material<br />Several quality checks<br />Complete in-house team</td>
                                            <td>Low quality products <br /> No quality checks <br />Outsourcing small suppliers   </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">WARRANTY</th>
                                            <td>11-year warranty<br />Assured on site service warranty</td>
                                            <td>No guarantee or Warranty <br />No after sales support </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">AFTER SALES SUPPORT</th>
                                            <td>Trained and highly professional support team</td>
                                            <td>No support </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Aruwa_edge;