import React from 'react';
import value_1 from '../../asset/images/modular-page-assets/value-1.png';
import value_2 from '../../asset/images/modular-page-assets/value-2.png';
import value_3 from '../../asset/images/modular-page-assets/value-3.png';
import value_4 from '../../asset/images/modular-page-assets/value-4.jpg';
import value_5 from '../../asset/images/modular-page-assets/value-5.jpg';
import value_6 from '../../asset/images/modular-page-assets/value-6.jpg';
import Marquee from "react-fast-marquee";



function Value_section() {
    const value_slider = [
        {
            'id' : 1,
            'image' : value_1,
            'title' : 'Self',
            'subtitle' : 'Manufacturing'
        },
        {
            'id' : 2,
            'image' : value_2,
            'title' : '50+',
            'subtitle' : 'Quality checks'
        },
        {
            'id' : 3,
            'image' : value_3,
            'title' : '45 Days',
            'subtitle' : 'Guaranteed Installation'
        },
        {
            'id' : 4,
            'image' : value_4,
            'title' : 'Price',
            'subtitle' : 'Match Guarantee'
        },
        {
            'id' : 5,
            'image' : value_5,
            'title' : 'Free',
            'subtitle' : '3D Virtual Tours'
        },
        {
            'id' : 6,
            'image' : value_6,
            'title' : '11 Years',
            'subtitle' : 'Warranty'
        },
    ];
    return (
        <>
            <section className="values py-5 p-md-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-3 text-center">
                            <h2>Values that makes us <span className="brown-text">Different</span></h2>
                        </div>
                    </div>
                    <div className="value-item-wrapper normal-grid">
                        <div className="value-item">
                            <img src={value_1} className="img-fluid" />
                            <div className='value-title'>
                                <h3>Self</h3>
                                <h4>Manufacturing</h4>
                            </div>
                        </div>
                        <div className="value-item">
                            <img src={value_2} className="img-fluid" />
                            <div className='value-title'>
                                <h3>50+</h3>
                                <h4>Quality checks </h4>
                            </div>
                        </div>
                        <div className="value-item">
                            <img src={value_3} className="img-fluid" />
                            <div className='value-title'>
                                <h3>45 Days</h3>
                                <h4>Guaranteed Installation</h4>
                            </div>
                        </div>
                        <div className="value-item">
                            <img src={value_4} className="img-fluid" />
                            <div className='value-title'>
                                <h3>Price</h3>
                                <h4>Match Guarantee</h4>
                            </div>
                        </div>
                        <div className="value-item">
                            <img src={value_5} className="img-fluid" />
                            <div className='value-title'>
                                <h3>Free</h3>
                                <h4>3D Virtual Tours</h4>
                            </div>
                        </div>
                        <div className="value-item">
                            <img src={value_6} className="img-fluid" />
                            <div className='value-title'>
                                <h3>11 Years</h3>
                                <h4>Warranty</h4>
                            </div>
                        </div>
                    </div>

                    <div className="value-item-wrapper marque ">
                    <Marquee speed={100}>
                        {
                            value_slider.map((value) => (
                                <div className="value-item">
                                    <img src={value.image} className="img-fluid" />
                                    <div className='value-title'>
                                        <h3>{value.title}</h3>
                                        <h4>{value.subtitle}</h4>
                                    </div>
                                </div>
                            ))
                       
                        }
                    </Marquee>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Value_section;