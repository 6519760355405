import { Outlet, Link , useNavigate } from 'react-router-dom';
import CookieConsent from '../pages/CookieConsent';
import logo from '../asset/images/logo.png';
import '../asset/css/style.css';
import Footer from '../components/Footer';
import React, { useEffect, useState } from 'react';
import 'react-light-accordion/demo/css/index.css';
import DropdownMenu from './Dropdown/DropdownMenu';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Button } from 'bootstrap';



const Nav = () => {


    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation();
    const currentSlug = location.pathname;

    const  schema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "What is the cost of interior design in Jaipur?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The cost of interior design in Jaipur varies widely based on project size, complexity, materials, and designer's experience. At aruwa you will find the best services in affordable price."
          }
        },{
          "@type": "Question",
          "name": "How to calculate the cost of interior design?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "To calculate interior design costs you need to define your scope, Set budget, then consult with the designer, estimate right materials/labor, evaluate all types of hidden costs, then get multiple quotes, define factor in design fees, sum all you costs and last but not least adjust your cost if needed."
          }
        },{
          "@type": "Question",
          "name": "What are the services offered by interior designers in Jaipur?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The interior designers in Jaipur offer a wide range of services like Modular kitchens, wardrobe designs, creating modular interior solutions, beautiful indoor spaces, and redecorating residential/commercial areas."
          }
        },{
          "@type": "Question",
          "name": "Who is the best interior designer in Jaipur?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Aruwa is the best interior designer in Jaipur provide wide range of services like Modular kitchens, wardrobe designs etc from 10+ years."
          }
        },{
          "@type": "Question",
          "name": "How do I choose the right interior designer in Jaipur, Rajasthan for my home?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Selecting the right interior designer in Jaipur, Rajasthan, involves thorough research and evaluation. Begin by shortlisting potential designers based on their portfolios, experience, and client reviews. Communicate with them to assess their understanding of your vision, budget compatibility, and communication skills. Request for references, review contracts, and consider their creativity and local knowledge. Trust your instincts and choose a designer with whom you feel comfortable collaborating for a successful home design project in Jaipur."
          }
        },{
          "@type": "Question",
          "name": "Why should I hire an interior designer or decorator in Jaipur, Rajasthan?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hiring an interior designer or decorator in Jaipur, Rajasthan, will brings expertise, cost-efficiency, and innovative design ideas. They blend traditional and contemporary elements effectively, creating personalized, aesthetically pleasing, and functional spaces while saving time and money."
          }
        }]
      }

    const  scrollToSection = () => {

      

  
        const section = document.querySelector('#scrollSection');

        const section2 = document.querySelector('#section-city-2');

        const section3 = document.querySelector('#section-city-3');

          if(section2){
            section2.scrollIntoView({ behavior: 'smooth' });
          }

          else

          if(section3){
            section3.scrollIntoView({ behavior: 'smooth' });
          }

          else 

          if(section)
          {
            section.scrollIntoView({ behavior: 'smooth' });
          }
          else{
            navigate('/contact-us');
          }
      };

    return (
        <>
            <Helmet>
                <title>Aruwa Interiors | Jaipur</title>
                <meta name="description" content="Aruwa gives a high quality modular solution to all your home like kitchen, Wardrobe, TV Panels, and more with the customized designs According all your needs and budget." />
                {currentSlug !== '/top-rated-interior-jaipur' && (
                        <script type="application/ld+json">{JSON.stringify(schema)}</script>
                    )}
            </Helmet>
            <section className="home-top-section  text-white">
                <header className="container-fluid sans-serif-font">
                    <div className="row lower-header">
                        <nav className="navbar navbar-expand-lg">
                            <div className="container-fluid">
                                <div className='row align-items-center w-100'>
                                    <div className='col-md-3'>

                                        <Link className="navbar-brand p-0 m-0 " to="/"><img className="w-50" src={logo} alt=""
                                        /></Link>

                                    </div>
                                    <div className='col-md-9'>

                                        <button className="navbar-toggler d-none " type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="fa-sharp fa-solid fa-bars"></i>
                                        </button>
                                        <div className="collapse navbar-collapse nav-bar-bg text-center" id="navbarSupportedContent"
                                        // style="justify-content: space-around;align-items:baseline;"
                                        >
                                            <ul className="navbar-nav me-auto  m-0">
                                                <li className="nav-item dropdown has-children">
                                                    <a className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" >Services</a>
                                                    <ul className="dropdown-menu  sub-menu">
                                                        <li><Link className="dropdown-item" to="/full-home-interiors">Full Home Interior</Link></li>
                                                        <li><Link className="dropdown-item" to="/modular-interior-solution">Modular Interior</Link></li>
                                                        <li><Link className="dropdown-item" to="/commercial-interior">Commercial Interior</Link></li>
                                                        <li><Link className="dropdown-item" to="/renovation-services-india">Rennovation Interior</Link></li>
                                                    </ul>
                                                </li>

                                                <li className="nav-item">
                                                    <Link className="nav-link" aria-current="page" to='/home-interior-design-ideas'>Design Gallery</Link>
                                                </li>

                                                <DropdownMenu></DropdownMenu>

                                                <li className="nav-item dropdown has-children">
                                                    <a className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" >Resources</a>
                                                    <ul className="dropdown-menu  sub-menu">
                                                        <li><Link className="dropdown-item" to="/blogs">Blogs</Link></li>
                                                        {/* <li><Link className="dropdown-item" to="/blogs">Magazine</Link></li>
                                                        <li><Link className="dropdown-item" to="/blogs">Newsletter</Link></li> */}
                                                    </ul>
                                                </li>



                                                <li className="nav-item">
                                                    <Link className="nav-link" aria-current="page" to='/contact-us'>Contact Us</Link>
                                                </li>
                                                <li className="nav-item dropdown has-children">
                                                    <a className="nav-link " role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page" >More</a>
                                                    <ul className="dropdown-menu  sub-menu">
                                                        <li><Link className="dropdown-item" to="/about-us">About Us</Link></li>
                                                        <li><Link className="dropdown-item" to="/career">Career</Link></li>
                                                        <li><Link className="dropdown-item" to="/franchise">Become Franchisee</Link></li>
                                                    </ul>
                                                </li>
                                                {/* <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to='/contact-us'>Go to Store</Link>
                                    </li> */}
                                            </ul>
                                            <button onClick={scrollToSection}  className="btn btn-gray p-3">Get Free Quote</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                </header>
            </section>
            <Outlet />
            <Footer />
        </>
    )
};

export default Nav;