const PrivacyAndPolicy =() =>{
    return (
        <>
        <section className="container pt-5">
    <div className="row pt-5">
        <div className="col-md-12 pt-3">
            <h3 className="display-6 fw-bold">Privacy Policy</h3>
            <br/>
            <p>Welcome to Aruwa’s privacy policy (referred to as “Policy”).
Aruwa Interior and its affiliates (collectively, “ARUWA”, “we”, “our” or “us”) are engaged inter alia in the business of modular furniture, interior design and décor services.</p>
<p>This Policy outlines our practices in relation to the collection, storage, usage, processing, and disclosure of personal data that you have chosen to share with us when you access, use, or otherwise interact with our website available at (website link).</p>
<p>ARUWA (collectively, “Platform”), avail products or services that Aruwa offers you on or through the Platform, or partner with us to provide customers with these services (collectively, the “Services”).</p>
<p>At Aruwa, safeguarding your privacy and ensuring the security of your personal data is our utmost priority. To enable us to provide you with our Services, it is necessary for us to collect and process specific information that you provide. This Policy outlines the details of how we process and utilise your personal data.</p>
<p>By using our Services, you acknowledge that you have read and accepted this.</p>

<h5> 1. BACKGROUND AND KEY INFORMATION </h5>
<h6> <b>(a) About Us: </b> </h6>
<p>ARUWA is the provider of the Services, and we are here to address any inquiries you may have regarding this Policy, the processing or handling of your personal data, or any other concerns. We encourage you to contact us at (care@aruwa.in) for any questions, feedback, grievances, or comments you may have.</p>
<h6> <b>(b) How this Policy applies:</b></h6>
<p>This Policy pertains to individuals who utilise our website, products, or services, including prospective customers (referred to as "Users"), as well as service providers who use our Platform to offer their services to Users (referred to as "Service Providers"). To avoid any confusion, when we mention "you" in this Policy, it encompasses both Users and Service Providers.</p>
<p>By engaging with our Services, you acknowledge and give consent to the collection, storage, usage, and disclosure of your personal data as described and collected by us, in adherence to the guidelines outlined in this Policy.</p>
<h6> <b>(c) Third-Party Links:</b></h6>
<p>Please be aware that the Platform may contain links to third-party websites, plug-ins, applications, and services. Clicking on these links or enabling such connections could result in the collection or sharing of your data by third parties. We want to emphasise that we have no control over these third-party websites and cannot be held responsible for their privacy statements. We strongly recommend that you review the privacy policy provided by the respective third party when you leave the Platform or access these external websites, plug-ins, applications, or services.</p>
<h5>2. PERSONAL DATA THAT WE COLLECT</h5>
<p> <b> (a)</b> When utilising the Services as a User, we collect various types of personal data pertaining to you. This includes, but is not limited to:</p>
        <p> <strong> Contact Data:</strong> This encompasses your mailing or home address, location, email addresses, and mobile numbers.</p>           
        <p> <strong>Property and Home Data: </strong> We gather information such as the name of your property, property type, size, preferred furniture and colour schemes, room details, number of homes owned, and whether the home is owned or rented.</p>   
        <p><b>Identity and Profile Data:</b>  This category includes your name, username or similar identifiers, photographs, details about family members (including their age and profession), credit information, gender, language, interests, and hobbies.</p>     
        <p> <b>Marketing and Communications Data: </b> This refers to your address, email address, information shared in service requests, offers, wants, feedback, comments, quizzes, pictures, and discussions on our blog and chat boxes. It also includes your responses to user surveys and polls, preferences for receiving marketing communications, and communication preferences. Additionally, we collect chat and call records when communicating with Service Providers through the Platform.</p>   
        <p> <b> Technical Data: </b>This encompasses your IP address, browser type, internet service provider, operating system details, access time, page views, device ID, device type, frequency of visiting our website and using the Platform, website and mobile application activity, clicks, date and time stamps, location data, and other technology-related information from devices used to access the Platform.</p>
        <p> <b>Transaction Data:</b> We collect details regarding the Services you have availed, including credit or debit card information for transaction tracking facilitated by payment processors, as well as UPI IDs for processing payments.</p>
        <p>Usage Data: This category includes information about your usage of the Services, activity on the Platform, booking history, user taps and clicks, user interests, time spent on the Platform, details of user journeys on the mobile application, and page views.</p>
        <p> <b> <strong>(b)</strong> </b>  When you use our Services as a Service Provider, we gather various types of personal data about you. This information may include, but is not limited to:</p>
        <p>Contact Data: This includes email addresses, phone numbers, and permanent addresses.</p>
        <p> <b>Usage Data:</b>  We collect call logs, mobile application usage and activity data, call and chat records with customers and end-users, and email usage information.</p>
        <p><b>Identity and Profile-related Data: </b>
        We collect your name, mobile number, gender, date of birth, age, address, zip code, description of services provided, content and information about your business (including photographs and videos), work experience, credit or debit card details, government-issued identifiers (such as Aadhaar and PAN details), verification reports, credit information, cancelled cheques, GST details, tax residency certificate, information from testimonials, polls, surveys, and feedback.
        </p>
        <p> <b>Technical Data:</b>  We gather your IP address, internet service provider, device ID, device type, domain name, operating system details, browser type, date and time stamps of accessing the Platform, device metadata, location data, and mobile applications used by you.</p>
        <p><b> Marketing and Communications Data:</b> This category includes feedback, comments, quizzes, pictures, and discussions in our blog and chat boxes, responses to user surveys and polls, your preferences for receiving marketing communications from us and third parties, as well as your communication preferences. Additionally, we collect your chat and call records when you communicate with Users through the Platform.</p>
        <p>Please note that this list is not exhaustive and may include other relevant personal data required for providing our Services as a Service Provider.</p>
        <p><b> (c)</b>  We may gather, utilise, and disclose aggregated data, including statistical or demographic data, for various purposes. While aggregated data may be derived from your personal information, it is not classified as personal data under the law as it does not disclose your identity directly or indirectly. However, if we combine or link aggregated data with your personal data in a way that it can identify you directly or indirectly, we handle the merged data as personal data, subject to the terms outlined in this Policy.

</p>
<p> <b>(d)</b>  What happens if I refuse to provide my personal data? </p>
<p>Where we need to collect personal data by law, or under the terms of a contract (such as the Terms), and you fail to provide that data when requested, we may not be able to perform the contract (for example, to provide you with the Services). In this case, we may have to cancel or limit your access to the Services, but we will, where reasonably practical, notify you if this is the case at the time.</p>
<h5>3. HOW DO WE COLLECT PERSONAL DATA? </h5>
<p>We use different methods to collect personal data from and about you through:</p>
<p>Direct Interactions- You provide us your personal data when you interact with us. This includes personal data you provide when you, for example: </p>
<ul>
    <li>create an account or profile with us at the time of registration;</li>
    <li>use the Platform or the Services;</li>
    <li>enter a promotion, user poll, or online surveys;</li>
    <li>request marketing communications to be sent to you; or</li>
    <li>report a problem with the Platform and/or our Services, give us feedback, contact us, or provide testimonials.</li>
</ul>
<p><b>Automated technologies or interactions-</b>  The Platform automatically gathers Technical Data about your equipment, browsing actions, and patterns each time you use it. We collect this personal data using cookies, web beacons, pixel tags, server logs, and other similar technologies. Additionally, if you visit other websites that employ our cookies, we may receive Technical Data about you.</p>
<p><b>Third parties or publicly available sources- </b> We will receive personal data about you from various third parties, including, without limitation:
</p>
<ul>
    <li>Background verifiers; </li>
    <li>Credit information from credit bureaus (to the extent permitted by law); </li>
    <li>Identity and profile-related data from publicly available sources; </li>
    <li>Technical Data from analytics providers such as Facebook, and advertising networks;  </li>
    <li>Our business partners through which you create or access the Platform, such as payment providers, social media services, or applications or websites who use our APIs or whose API we use </li>
    <li>Marketing service providers.  </li>
</ul>
<h5>4. HOW DO WE USE YOUR PERSONAL DATA?</h5>
<p><b>(a) We will only use your personal data when the law allows us to do so. Most commonly, we will use your personal data where we need to provide our Services, or where we need to comply with a legal obligation. We use your personal data for the following purposes:
</b></p>
<ul>
    <li>to perform our obligations that arise out of the arrangement we are about to enter or have entered with you; </li>
    <li>to enforce our Terms; </li>
    <li>to market our Services to you;</li>
    <li>to comply with our legal obligations; and</li>
    <li>for product development and testing;</li>
    <li>to improve customer service to effectively respond to Users’ Service requests, grievances, and support needs;</li>
    <li>to develop new product features and also facilitate financial solutions and insurance that our connected to our Services</li>
    <li>to track transactions and process payments; </li>
    <li>to send periodic notifications to manage our relationship with you including to notify you of changes to our Services, and to receive occasional company news and updates related to us or the Services;</li>
    <li>to monitor your activities on the Platform;</li>
    <li>to monitor trends and personalise your experience;</li>
    <li>to improve the functionality of our Services based on the information and feedback we receive from you;</li>
    <li>to assist with the management of the Services offered to you, including to send you information and updates about the Services you have availed;</li>
    <li>to administer and protect our business and the Platform, including for troubleshooting, data analysis, system testing, and performing internal operations;</li>
    <li>to improve our business and delivery models;</li>
    <li>to respond to court orders, establish or exercise our legal rights, or defend ourselves against legal claims</li>
    <li>to verify your identity, and create your user account with us on the Platform at the time of registration;</li>
    <li>to provide the Services to you including processing your order and providing you with end-to-end interior design and execution services;</li>
    <li>if you are a Service Provider, to assess the performance of your services rendered to User</li>
</ul>
<p><b>(b)</b> By accessing the Platform or utilizing our Services, you explicitly agree and acknowledge that we, along with our associate partners, affiliates, end-users, customers, business partners, agents, and other third parties, are authorized to contact you through various means such as email, phone, or other communication channels. The purpose of these contacts is to facilitate the provision of Services, share marketing materials, and ensure your awareness of all the features available. Additionally, you also provide express consent and authorization to ARUWA, its affiliates, authorized partners, service providers, vendors, and other third parties to reach out to you. The intention behind such communication is to offer or invite your interest in availing other products or services provided by third parties. These communications may be conducted via telephone, SMS (short messaging service), electronic mail (e-mail), WhatsApp, or any other messaging service/mobile application, as well as through physical, electronic, or digital means/modes.</p>
<p><b>(c)</b> You acknowledge and agree that any information related to you, regardless of whether you provide it directly to us (through the Services or otherwise), including personal correspondence like emails or instructions, may be collected, compiled, and shared by us to fulfill the Services provided to you. This may involve sharing with vendors, social media companies, third-party service providers, storage providers, data analytics providers, consultants, lawyers, and auditors. Furthermore, we may also share this information with other entities within ARUWA Interior for the aforementioned purposes.</p>
<p><b>(d) </b>You agree and acknowledge that we may share data without your consent when it is required by law or by any court or government agency or authority to disclose such information. Such disclosures are made in good faith and belief that it is reasonably necessary to do so for enforcing this Policy or the Terms, or in order to comply with any applicable laws and regulations.</p>
<h5>5. COOKIES</h5>
<p><b>(a)</b> We use cookies to distinguish you from other users of our Platform and remember your preferences. This helps us to provide you with a seamless user experience when you use our Platform and also allows us to improve our Services.

</p>
<h5>6. DISCLOSURES OF YOUR PERSONAL DATA</h5>
<p>
<b>(a)</b> We may share your personal data with third parties set out below for the purposes set out in Section 4:
</p>
<p>(i) External third parties such as:</p>
<ul>
    <li>trusted third parties such as our associate partners, and service providers that provide services for us or on our behalf. This includes hosting and operating our Platform, providing marketing assistance, conducting our business, processing payments and transaction-related processes, transmitting content, conducting credit-assessment risks for short term credits availed by you from us, processing your insurance-related claims, and enabling us to provide the Services to our end-users or customers; </li>
    <li>analytic service providers and advertising networks that conduct web analytics to help us improve our Platform and Services. These analytics providers may use cookies and other technologies to perform their services; and,</li>
    <li>regulators and other bodies, as required by law or regulation.</li>
    <li>If you are a User, Service Providers to enable them to provide specific services to you;</li>
    <li>If you are a Service Provider, Users who seek to use the services you choose to offer through the Platform;</li>
</ul>
<p><b>(b)</b> We require all third parties to respect the security of your personal data and to treat it in accordance with the law.</p>
<h5>7. ACCESS AND UPDATING YOUR PERSONAL DATA</h5>
<p>You hereby warrant that all personal data that you provide us with is accurate, up-to-date, and true. When you use our Services, we make our best efforts to provide you with the ability to access and correct inaccurate or deficient data, subject to any legal requirements.</p>
<h5>8. TRANSFERS OF YOUR PERSONAL DATA</h5>
<p>
<b>(a)</b> We comply with applicable laws in respect of the storage and transfers of personal data. As a part of your use of the Services, the information and personal data you provide to us may be transferred to and stored in countries other than the country you are based in. This may happen if any of our servers are from time to time located in a country other than the one you are based, or one of our service providers is located in a country other than the one you are based in. 
</p>
<p><b>(b)</b> By submitting your information and personal data to us, you agree to the transfer, storage, and processing of such information and personal data in the manner described above. </p>
<h5>9. DATA SECURITY</h5>
<p>We implement appropriate security measures to protect your personal data from unauthorised access, and follow standards prescribed by applicable law.
 
 </p>
 <h5>10. DATA RETENTION</h5>
 <p>
    <b>(a)</b> You agree and acknowledge that your personal data will continue to be stored and retained by us for as long as necessary to fulfil our stated purpose(s) and for a reasonable period after the termination of your account on the Platform or access to the Services to comply with our legal rights and obligations.

 </p>
 <p> <b>(b)</b> In some circumstances, we may aggregate your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.

</p>
<h5>11.BUSINESS TRANSITIONS </h5>
<p>You are aware that in the event we go through a business transition, such as a merger, acquisition by another organisation, or sale of all or a portion of our assets, your personal data might be among the assets transferred. 

</p>
<h5>12.OPT OUT</h5>
<p>If you wish to opt out or you do not wish to be contacted over telephonic calls, kindly send an email to (care@aruwa.in)  from your registered email address at Livspace, along with your contact number. Upon receipt of this email, your telephone number(s) will be removed from all our telemarketing calling lists within 15 working days.</p>
<h5>13.UPDATES TO THIS POLICY</h5>
<p><b>(a) </b>We regularly review and may occasionally update our Policy. If we make changes to this Policy, we will notify you of the changes through the Platform or through other means, such as email. We encourage you to periodically review this Policy for the latest information on our privacy practices.</p>
<p><b>(b) </b>It is also important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
<p>
<b>(c)</b> To the extent permitted under applicable law, by accessing our Platform or Services after such notice, you consent to updates made to this Policy.
</p>
<h5 className="my-5 text-center">If you have any query or concerns regarding this Privacy Policy, you may always submit such concerns via email to (<a href="mailto:care@aruwa.in">care@aruwa.in</a> )

</h5>
        </div>
    </div>
</section>
<section className="container-fluid bg-yellow py-5">
    <section className="container">
        <div className="row">
            <div className="col-md-6 p-0 d-none d-md-block text-left">
                <div className=" h-100">
                    <h4 className="display-6 fw-bold mb-4">Signup to our newsletter</h4>
                    <h5 className="fw-lighter">Stay up to date with the latest news and articles.</h5>
                </div>
            </div>
            <div className="col-md-6 px-md-1 py-md-3 p-4">

                <form className="newsletter-form">
                    <div className="row">
                        <div className="col-8">
                            <input type="email" className="form-control h-100 border-dark bg-yellow " id="exampleInputEmail1"
                                aria-describedby="emailHelp" placeholder="Enter Your Email"/>
                        </div>
                        <div className="col-4">
                            <button type="submit"
                                className="btn btn-dark text-capitalize px-md-5 py-md-3 ">Subscribe</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </section>
</section>
        </>
    )
}

export default PrivacyAndPolicy