import React from 'react';
import calculate_back from '../../asset/images/modular-page-assets/calculate-back.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Calculate_now(props){

return(
<>
{/* <section className="calculate-now py-5 back-cover back-bottom" style={{ backgroundImage: `url(${calculate_back})` }}> */}
<section className="calculate-now py-3 py-md-5 back-cover back-bottom" >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8">
                            <h5 className="text-white text-center calculate-title"> {props.page_title} </h5>
                        </div>
                        <div className="col-md-4 text-md-end">
                            <a href="#" className="btn btn-full btn-theme-light p-3 p-md-4">{props.btn_text ? props.btn_text : 'Calculate Now' } <FontAwesomeIcon icon={faArrowRight} /> </a>
                        </div>
                    </div>
                </div>
            </section>
</>
);
}

export default Calculate_now;