import Faq2 from "../../../components/Faq2";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
const ModularKitcheAlwar = () => {
  const [activeImage, setActiveImage] = useState(null);

  const handleImageHover = (imageNumber) => {
    setActiveImage(imageNumber);
  };

  const handleImageClick = (imageNumber) => {
    // Optionally, you can add specific logic for click events here.
    setActiveImage(imageNumber);
  };
  return (
    <>
      <Helmet>
        <title>
          Modular Kitchen Interior Designs|Modular Kitchen Designer In Alwar
        </title>
        <meta
          name="description"
          content="Aruwa Offers Best Modular Kitchen Interior Designs by expert Modular Kitchen Designer In Alwar with latest Modular Kitchen Designs In Alwar. "
        />
      </Helmet>

      <div
        className=" banner-top bg-dark pt-5 vh-100"
        style={{
          backgroundImage:
            "url('../../asset/images/banner/Modular kithen.jpg')",
        }}
      >
        <div className="container-fluid  h-100">
          <div className="row align-items-center pt-5 h-100">
            <div className="col-12 col-xl-6  ps-md-100 text-center text-md-start">
              <h2 className="text-center text-md-start display-6 fw-bold mb-4">
              Top Rated Modular Kitchen Dealers in Alwar, Rajasthan {" "}
              </h2>
              <h5 className="text-center text-md-start mb-4">
              Welcome to Aruwa Interiors, the home of the best modular kitchen dealers in Alwar {" "}
              </h5>
              <p>
              Experience the joy of cooking in a kitchen that truly reflects your style and enhances your daily life.
              </p>
              <button  className="btn btn-outline-warning rounded-0 py-3 px-5">
                Get Started
              </button>
              <div className="col-md-6 "></div>
            </div>
            <div className="col-12 col-md-6  d-none d-xl-flex position-relative row align-items-md-end h-100  ">
              <img
                src="/../../asset/images/modular-kitchen/Path 54379.svg"
                className="w-50 position-absolute"
                style={{
                  zIndex: 1,
                  top: "-10%",
                  left: "5%",
                  transform: "rotate(350deg)",
                }}
                alt=""
              />
              <img
                src="/../../asset/images/modular-kitchen/Scroll Group 442x.png"
                className="w-100"
                style={{ zIndex: 15 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <section className=" py-md-5 py-3 bg-white">
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-9 mb-md-4">
              <span className="my-3 text-uppercase">
                <b>HOW THIS WORKS  </b>
              </span>
              <div className="heading-line"></div>
              <h3 className="display-5 fw-bold mb-md-4">
                The modular kitchen that spread our words {" "}
              </h3>
              <p>
              150+ projects delivered successfully across the pink city of Alwar.
              </p>
            </div>
            <div className="col-md-3 text-center">
              <Link to={'/contact-us'} className="btn  btn-outline-dark text-capitalize px-md-5  py-md-3 rounded-0 my-3">
                Be Our Next Story
              </Link>
            </div>
            <div className="row align-items-stretch">
              <div className="col-md-4 p-2">
                <div className="bg-light p-3 h-100">
                  <div className="flex flex-column">
                    <div className="col-md-12   position-relative col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/icons/Polygon 6.svg"
                        className="position-absolute play-icon"
                        alt=""
                      />
                      <img
                        src="/../../asset/images/modular-kitchen/modern-kitchen-design-with-stainless-steel-appliances-generative-ai.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Maya Sharma</h5>
                      <p className="mb-2">House wife</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className="bg-light p-3 h-100">
                  <div className="flex flex-column">
                    <div className="col-md-12  position-relative col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/icons/Polygon 6.svg"
                        className="position-absolute play-icon"
                        alt=""
                      />
                      <img
                        src="/../../asset/images/modular-kitchen/young-couple-cooking-home.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Charu Khandelwal</h5>
                      <p className="mb-2">House wife</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className="bg-light p-3 h-100">
                  <div className="flex flex-column">
                    <div className="col-md-12 position-relative col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/icons/Polygon 6.svg"
                        className="position-absolute play-icon"
                        alt=""
                      />
                      <img
                        src="/../../asset/images/modular-kitchen/beautiful-green-kitchen-interior-design (2).png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Anita Mishra</h5>
                      <p className="mb-2">Doctor</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-md-5 py-3 text-dark bg-white">
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-9 mb-4 text-black">
              <span className="my-3 text-uppercase">
                <b>HOW THIS WORKS   </b>
              </span>
              <div className="heading-line"></div>
              <h3 className="display-5 fw-bold mb-md-4">
              Choose from a design layout that meets your needs 
              </h3>
              <p>we understand that the kitchen is the heart of every home</p>
            </div>
            <div className="col-md-3 text-md-right"></div>
            <div className="row align-items-stretch justify-content-center text-black">
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/beautiful-green-kitchen-interior-design (3).png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Straight Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/3d-rendering-modern-kitchen-counter-with-white-biege-design.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>L-Shaped Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 67.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>U-Shaped Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 68.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Parallel Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 69.png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>G-Shaped Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 p-2">
                <div className=" p-3 h-100">
                  <div className="row flex-column">
                    <div className="col-md-12 col-12 p-0 text-center mb-3">
                      <img
                        src="/../../asset/images/modular-kitchen/beautiful-shot-modern-house-kitchen (1).png"
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="col-md-12 col-12 p-0 text-center text-md-start">
                      <h5>Island Modular Kitchen</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center">
                <Link
                  to={"/contact-us"}
                  className="btn  btn-outline-dark text-capitalize px-md-5 py-md-3 rounded-0 my-3 mt-md-5"
                >
                  Start your modular journey
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-3 pt-5 bg-color-gray text-light slanted-bottom">
        <div className="container">
          <div className=" col-md-10 mb-4 px-md-5">
            <span className="mb-3">
              <b>Why choose Aruwa for your modular kitchen in Alwar?</b>
            </span>
            <div className="heading-line"></div>
            <h3 className="display-5 fw-bold mb-md-4">
            Unlock the potential of your kitchen with the proven power of Aruwa's modular solutions.
            </h3>
            {/* <p>
              
                Let’s take a look at the advantages of joining our franchisee programme
            </p> */}
          </div>
        </div>
      </section>
      <section className=" py-md-5 py-3 text-dark bg-yellow slanted-top-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Exceptional Craftsmanship</h5>
                 <p>Aruwa takes pride in its meticulous craftsmanship, ensuring that every modular kitchen is designed and built to perfection.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Customized Solutions</h5>
               <p>We offer tailor-made kitchen solutions that cater to your unique preferences, ensuring your kitchen reflects your style and needs.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Innovative Designs</h5>
                  <p>Aruwa's modular kitchens are known for their innovative designs, keeping up with the latest trends and technologies to provide you with a modern kitchen space.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Professional Installation</h5>
                 <p>Our experienced team of installers ensures a hassle-free and precise installation process, leaving you with a fully functional kitchen.
</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Quality Materials</h5>
                  <p>We use top-quality materials, including durable cabinets, countertops, and hardware, to ensure the longevity and functionality of your kitchen.</p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="row">
                <div className="col-md-10 text-black col-12 p-md-0 text-center text-md-start">
                  <h5>Space Optimization</h5>
                <p>Our modular kitchens are designed with a focus on maximizing space utilization, making the most of even the smallest kitchen areas.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section className="py-3 pt-md-5 text-black">
        <div className="container">
          <div className=" col-md-10 mb-4 p-0">
            <span className="mb-3">
              <b>Expert Ask</b>
            </span>
            <div className="heading-line"></div>
            <h3 className="display-5 fw-bold mb-md-4">
              Other modular expert services we offer
            </h3>
          </div>
        </div>
      </section>
      <section className="py-md-5 py-3 text-black">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-md-6 p-0 py-2 px-4 mb-3">
              <div className="row align-items-end">
                <div className="col-md-7 px-1">
                  <div className="row align-items-end">
                    <div className="col-12 col-md-6 p-1">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 70.png"
                        className={`w-100 ${
                          activeImage === 1 ? "active-image" : ""
                        }`}
                        alt=""
                        onMouseEnter={() => handleImageHover(1)}
                        onClick={() => handleImageClick(1)}
                      />
                    </div>
                    <div className="col-12 col-md-6 p-1">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 72.png"
                        className={`w-100 ${
                          activeImage === 2 ? "active-image" : ""
                        }`}
                        alt=""
                        onMouseEnter={() => handleImageHover(2)}
                        onClick={() => handleImageClick(2)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 p-1">
                      <img
                        src="/../../asset/images/modular-kitchen/Mask Group 71.png"
                        className={`w-100 ${
                          activeImage === 3 ? "active-image" : ""
                        }`}
                        alt=""
                        onMouseEnter={() => handleImageHover(3)}
                        onClick={() => handleImageClick(3)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-12 p-0 px-md-3 pb-1">
                  <img
                    src="/../../asset/images/modular-kitchen/Mask Group 73.png"
                    className={`w-100 ${
                      activeImage === 4 ? "active-image" : ""
                    }`}
                    alt=""
                    onMouseEnter={() => handleImageHover(4)}
                    onClick={() => handleImageClick(4)}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 py-2 px-4 mb-3">
              <div className="text-black p-md-0 text-center text-md-start">
                <h3>
                  {activeImage === 1
                    ? "Personalized Wardrobes starting Rs.1.25 Lacs"
                    : activeImage === 2
                    ? "Personalized Wardrobes starting Rs.2.25 Lacs"
                    : activeImage === 3
                    ? "Personalized Wardrobes starting Rs.3.25 Lacs"
                    : activeImage === 4
                    ? "Personalized Wardrobes starting Rs.4.25 Lacs"
                    : "Personalized Wardrobes starting Rs.5.25 Lacs"}
                </h3>
                <Link
                  to={"/contact-us"}
                  className="btn btn-outline-dark text-capitalize px-md-5 py-md-3 rounded-0 my-3 my-md-5"
                >
                  Book Appointment
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h2>
          Why Aruwa Interiors Should be Your Choice as the best modular kitchen designer in Alwar?{" "}
          </h2>
          <p>
          With a commitment to exceptional craftsmanship and innovative design, we bring you a range of modular kitchens that redefine culinary spaces and elevate your cooking experience.
          </p>
          <p>
          At Aruwa Interiors, we understand that the kitchen is the heart of every home, where delicious meals are prepared and cherished memories are made. As the leading modular kitchen dealers in Alwar, we take pride in offering stylish and functional kitchen solutions that cater to your unique needs and preferences.
          </p>
          <p>
          Our team of experienced designers and skilled craftsmen work closely with you to create a modular kitchen that is tailor-made for your space. With a focus on storage optimization, and aesthetic appeal, our modular kitchens are designed to maximize functionality.
          </p>
          <p>
          As the best modular kitchen designers & dealers in Alwar, we source only the finest materials and fittings, ensuring durability and longevity. Whether you<b> prefer a sleek and contemporary design</b> or a more traditional and rustic look, we have a wide range of options to suit your style and budget.
          </p>
          <p>
          With Aruwa Interiors, you can expect a hassle-free experience. Our dedicated team is committed to delivering exceptional customer satisfaction, guiding you through every step of the process. We believe in transparency, providing you with detailed information about materials, costs, and timelines.
          </p>
          <p>
          When you choose Aruwa Interiors as your modular kitchen dealer in Alwar, you are investing in a space that seamlessly combines functionality, aesthetics, and durability. {" "}
          </p>
          <h5>
          For the best modular kitchen solutions in Alwar, trust Aruwa Interiors. 
          </h5>
          <h6>
          Contact us today to schedule a consultation and let us bring your dream kitchen to your home.
          </h6>
        </div>
      </section>

      <section className=" py-md-5 py-3">
        <div className="container">
          <div className="row justify-content-start align-items-center">
            <div className=" col-12 col-md-8 mb-4">
              <span className="my-3 ">
                <b>Faq's</b>
              </span>
              <div className="heading-line"></div>
            </div>
            <div className="col-12 col-md-4 text-center text-md-end">
              <Link
                to="/contact-us"
                className="btn  btn-outline-dark text-capitalize px-md-5 rounded-0"
              >
                Contact us
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-0">
              <Faq2 categorySlug={"modular-kitchen-in-alwar"}></Faq2>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="row bg-black">
          <div className="col-md-4 p-0 d-none d-md-block text-center position-relative">
            <div className="polygon-path-clip-1 h-100 w-100 position-absolute bg-yellow"></div>
            <img
              src="/../../asset/images/cost/Mask Group 63.png"
              alt=""
              className="position-relative"
              style={{ zIndex: 999 }}
            />
          </div>
          <div className=".polygon-path-clip-2 col-md-8 p-4 px-md-1 py-md-4 bg-yellow">
            <div className=" h-100">
              <h3 className="display-5 fw-bold mb-4">
                Have You any question about your home interior ?
              </h3>
              <a
                href="tel:18008907797"
                className="btn  btn-outline-dark text-capitalize px-md-5 py-md-3 rounded-0"
              >
                Talk to our expert
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ModularKitcheAlwar;
