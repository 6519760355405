import Faq2 from "../../components/Faq2";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React, { useState } from "react";
import BannerSlider from "../../components/BannerSlider";
import why_us_back from '../../asset/images/modular-page-assets/why-us-back.jpg'
import config from "../../config";
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'
import form_image from '../../asset/images/modular-page-assets/form-image.jpg'
import Counter_section from "../../components/Static-Section/Counter-section";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Value_section from "../../components/Static-Section/Values_Section";
import Calculate_now from "../../components/Static-Section/Calculate_section";
import CustomerSection from "../../components/Static-Section/CustomerSection";
import background1 from '../../asset/images/banner-slider/modular-kitchen/banner-back1.jpg';
import background2 from '../../asset/images/banner-slider/modular-kitchen/banner-back2.jpg';
import CityContact from "../../components/CityContact";
import whyChoose from "../../components/Static-Section/whyChoose";



const ModularKitcheJaipur = () => {

    const [activeData, setActiveData] = useState("Data 1");

    const handleButtonClick = (data) => {
        setActiveData(data);
    };

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        city: "Jaipur",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setSuccessMessage(null);
        fetch(`${config.base_url}contact-us`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                // Check if the request was successful
                if (response.ok) {
                    setSuccessMessage("Form submitted successfully");
                    setFormData({
                        name: "",
                        mobile: "",
                        email: "",
                        city: "Jaipur",
                        message: "",
                    });
                } else {
                    throw new Error("Form submission failed");
                }
            })
            .catch((error) => {
                console.error("Form submission failed:", error);
            })
            .finally(() => {
                setIsLoading(false); // Reset the loading state regardless of success or failure
            });
    };



    const [activeImage, setActiveImage] = useState(null);

    const handleImageHover = (imageNumber) => {
        setActiveImage(imageNumber);
    };

    const handleImageClick = (imageNumber) => {
        // Optionally, you can add specific logic for click events here.
        setActiveImage(imageNumber);
    };

    const slides = [
        {
            'id': 1,
            'title': 'Top Rated Modular Kitchen in Jaipur, Rajasthan',
            'sub_title': "Aruwa is the most trusted and reputed Modular Kitchen Manufacturer in Jaipur that provides modular solutions for all your kitchen interior requirements",
            'background': background2,
            'back_position': 'back-bottom-modular-1',
            'link': '/contact-us'

        },
        {
            'id': 2,
            'h2_title': 'Top Rated Modular Kitchen in Jaipur, Rajasthan',
            'sub_title': "Aruwa is the most trusted and reputed Modular Kitchen Manufacturer in Jaipur that provides modular solutions for all your kitchen interior requirements",
            'background': background1,
            'back_position': 'back-bottom-modular-2',
            'link': '/contact-us'
        },
    ];

    return (
        <>
            <main className="sans-serif-font">
                <Helmet>
                    <title>
                        Modular Kitchen in Jaipur | Modular Kitchen Manufacturer in Jaipur
                    </title>
                    <meta
                        name="description"
                        content="We are the top modular kitchen manufacturer in Jaipur. 
Aruwa delivers best-in-class modular kitchen services and
design layouts that are customized as per the customer’s budget and requirements "
                    />

                    <link rel="canonical" href="https://aruwa.in/modular-kitchen-jaipur" />

                </Helmet>

                <section>
                    <div className="container-fluid ">
                        <div className="row ">
                            <BannerSlider slides={slides}></BannerSlider>
                        </div>
                    </div>
                </section>
                <section className="banner-content-mb">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>Top Rated Modular Kitchen in Jaipur, Rajasthan</h1>
                                        <p className='banner-subtitle mb-0'>Aruwa is the most trusted and reputed Modular Kitchen Manufacturer in Jaipur that provides modular solutions for all your kitchen interior requirements</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="d-md-none" id='section-city-1'>
                    <CityContact />
                </div>

                <Counter_section></Counter_section>

                <whyChoose></whyChoose>

                <section className=" py-md-5 py-3 text-dark bg-white">
                    <div className="container">
                        <div className="row justify-content-center justify-content-md-start">
                            <div className="col-md-12 mb-4 text-black text-center">
                                <h2 className="fw-bold mb-md-4">
                                    Offering <span className="yellow-text">Modular Kitchen</span> layouts
                                </h2>
                                <p>Aruwa delivers the best-in-class modular kitchen services and design layouts that are customised as per the customer’s budget and requirements. Our modular kitchen solutions ensure that every side and corner of your kitchen is utilised effectively to provide maximum functionality with an added spice of aesthetics.</p>
                            </div>
                            <div className="row align-items-stretch justify-content-center text-black">
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/straight_modular_kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>Straight Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/L_Shaped_Modular_Kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>L-Shaped Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/U_shape_modular_kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>U-Shaped Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/Parallel_Modular_Kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>Parallel Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/G_Shaped_Modular_Kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>G-Shaped Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-6 p-2">
                                    <div className=" p-3 h-100">
                                        <div className="row flex-column">
                                            <div className="col-md-12 col-12 p-0 text-center mb-3">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/Island_Modular_Kitchen.png'}
                                                    className="w-100"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                                <h5>Island Modular Kitchen</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 text-center">
                                    <Link
                                        to={"/contact-us"}
                                        className="btn hire-us radius-50 my-3"
                                    >
                                        BOOK FREE CONSULTATION
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="why-us-section py-5 p-md-5 back-cover" style={{ backgroundImage: `url(${why_us_back})` }}>
                    <div className="container">
                        <div className="row mb-3">
                            <div className="col-md-12 text-md-center">
                                <h2>Why Choose Aruwa Services as your <span className="yellow-text no-black">Modular Kitchen</span> in Jaipur?</h2>
                                <p>We have been crafting customized interior modular kitchen solutions since 2014 and offer a comprehensive solution for all modular kitchen design needs. Our portfolio showcases over 200 successful modular kitchen design projects with over 5k satisfied customers.</p>
                            </div>
                        </div>
                        <div className="row mx-md-5">
                            <div className="col-md-12">
                                <div className="why-choose-wrapper">

                                    <div className="why-choose">
                                        <h4>Exceptional Craftsmanship</h4>
                                        <p>Aruwa takes pride in its meticulous craftsmanship, ensuring that every modular kitchen is designed and built to perfection</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Innovative Designs</h4>
                                        <p>Aruwa's modular kitchens are known for their innovative designs, keeping up with the latest trends and technologies to provide you with a modern kitchen space.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Quality Materials</h4>
                                        <p>We use top-quality materials, including durable cabinets, countertops, and hardware to ensure the longevity and functionality of your kitchen</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Customized Solutions</h4>
                                        <p>We offer Tailor-made kitchen solutions that cater to your unique preferences, ensuring your kitchen reflects your style and needs.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Professional Installation</h4>
                                        <p>Our experienced team of installers ensures a hassle-free and precise installation process,leaving you with a fully functional kitchen.</p>
                                    </div>
                                    <div className="why-choose">
                                        <h4>Space Optimization</h4>
                                        <p>Our modular kitchens are designed with a focus on maximizing space utilization, making the most of even the smallest kitchen areas.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Aruwa_edge></Aruwa_edge>


                <Value_section></Value_section>

                <CustomerSection></CustomerSection>

                <div className="d-none d-md-block" id='section-city-2'>
                    <CityContact />
                </div>

                <section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
                    <div className="test-layer-1">
                        <img src={test_item_1} className="img-fluid" />
                    </div>
                    <div className="test-layer-2">
                        <img src={test_item_2} className="img-fluid" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
                                <Faq2 categorySlug={"modular-kitchen-jaipur-new"} ></Faq2>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
};

export default ModularKitcheJaipur;
