
import React, { useState ,useEffect} from 'react';
import Spinner from "../../components/Spinner/Spinner";
import config from "../../config";
import Moment from 'moment';
import { Link } from "react-router-dom";
const Blogs = () => {
    const uploadUrl = `${config.base_uploadUrl}post_type_pages/`;
    
        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);
        const [blogsData, setBlogs] = useState([]);

        useEffect(() => {
            fetch(`${config.base_url}blogs`)
              .then((res) => res.json())
              .then(
                (result) => {
                  setIsLoaded(true);
                  setBlogs(result);
                },
                (error) => {
                  setIsLoaded(true);
                  setError(error);
                }
              );
          }, []);

      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div>
            <Spinner />
          </div>
        );
      } else {
    return (
        <>
            <section className="pt-5 ">
                <div className="container pt-5 pb-5">
                    <div className="row pt-2">
                        <div className="col-md-3 d-flex align-items-end justify-content-end">
                            <div>
                                <img src="/../../asset/images/blog/Group 25451.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div>
                                <h1 style={{fontSize:'3.75rem'}}>Inside Design : Stories & Interview</h1>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <img src="/../../asset/images/blog/Group 25450.svg" alt=""/>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="mt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 position-relative" >
                            <div className="ms-md-5">
                                <img src="/../../asset/images/blog/Group 25448.png" className="w-100" alt=""/>
                            </div>
                            <div className="green-interior me-md-5">
                                <div className="d-flex">
                                    {/* <div className="col-md-7 "> */}
                                    {/* <OwlCarousel  {...options} className="owl-theme">
                                        <div className="shadow py-5 bg-white item">
                                            <h3 className="ps-4 fw-bold mt-2">Green Interior</h3>
                                            <p className="ps-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</p>
                                            <div className="ps-4 py-1 w-50">
                                                <button className="btn btn-outline-dark py-md-1 px-md-4 radius-50 mb-3 w-100">Office
                                                    Interior</button>
                                                    <button className="btn btn-outline-dark py-md-1 px-md-4 radius-50 w-100">Research</button>
                                            </div>
                                            <div className="ps-4 mt-5">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span>04 May 23 Admin</span>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </div>
                                        <div className="shadow py-5 bg-white item">
                                            <h3 className="ps-4 fw-bold mt-2">Green Interior</h3>
                                            <p className="ps-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.</p>
                                            <div className="ps-4 py-1 w-50">
                                                <button className="btn btn-outline-dark py-md-1 px-md-4 radius-50 mb-3 w-100">Office
                                                    Interior</button>
                                                    <button className="btn btn-outline-dark py-md-1 px-md-4 radius-50 w-100">Research</button>
                                            </div>
                                            <div className="ps-4 mt-5">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <span>04 May 23 Admin</span>
                                                    </div>
                                                   
                                                </div>

                                            </div>
                                        </div>
                                    </OwlCarousel> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="container">
                                <h4 className="py-2">Recent Stories</h4>
                                {blogsData.blogs.map((data) => {
                                    return(
                                <div className="row mb-1" key={data.id}>
                                   
                                    <div className="col-3 py-2">
                                        <div className="div ">
                                            <img src={`${uploadUrl}${data.featured_image}`} className="w-100" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-9 py-2">
                                    <Link  className="btn" to={`/blog/${data.slug}`}> 
                                        <h6>{data.title}</h6>
                                        </Link> 
                                    </div>
                                     
                                </div>
                                 );
                                })}
                                <h4 className="py-1">Categories</h4>
                                <div className="row">
                                {blogsData.blogs_category.category.map((data) => {
                                    return(
                                    <div className="col-md-5 px-1 col-sm-3 col-6" key={data.id}>
                                        <div className="text-center py-1 m-1">
                                            <button className="btn btn-outline-dark py-md-2 px-md-5 rounded-0 w-100">{data.category_name}</button>
                                        </div>
                                    </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  */}
            <section>
                <div className="container mt-4">
                    <div className="row align-items-stretch">
                    {blogsData.blogs.map((data) => {
                                    return(
                        <div className="col-md-4 p-3 p-md-2 mb-md-5" key={data.id}>
                            <div className=" h-100">
                                <Link  className="btn" to={`/blog/${data.slug}`}>
                                    <div className="flex flex-column">
                                        <div className="col-md-12 col-12 p-0 text-center mb-3">
                                            <img src={`${uploadUrl}${data.featured_image}`} className="w-100"
                                                alt=""/>
                                        </div>
                                        <div className="col-md-12 col-12 p-0 text-center text-md-start">
                                            <h4 className="mb-2 mb-md-3 fw-bold">{data.title}</h4>
                                            <p className="mb-2 mb-md-3">{data.short_desc}</p>
                                            <span className="me-4">{Moment(data.created_at).format('D MMM YY')}</span><span>{data.author.name}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                              );
                            })}
                    </div>
                </div>
            </section>
            {/*  */}
            <section className="container-fluid bg-yellow py-5">
                <section className="container">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <div className=" h-100">
                                <h4 className="display-6 fw-bold mb-md-4">Signup to our newsletter</h4>
                                <h5 className="fw-lighter">Stay up to date with the latest news and articles.</h5>
                            </div>
                        </div>
                        <div className="col-md-6 px-md-1 py-md-3 p-4">

                            <form className="newsletter-form">
                                <div className="row">
                                    <div className="col-7">
                                        <input type="email" className="form-control h-100 border-dark bg-yellow "
                                            id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Email"/>
                                    </div>
                                    <div className="col-5">
                                        <button type="submit"
                                            className="btn btn-dark text-capitalize px-md-5 py-md-3 ">Subscribe</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </section>
            </section>
        </>
    );}
  };
   export default Blogs;