import React, { useState ,useEffect} from 'react';
import Spinner from "../components/Spinner/Spinner";
import { Outlet, Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
// import background from '../../public/asset/images/icons/service-1/icons8-bill-64.png';




const BannerSlider = (props) => {

    const options = {
        items: 1, // Number of items to show
        loop: false, // Enable looping
        autoplay: true, // Enable autoplay
        nav: true,
        dots: true,
        autoplaySpeed: 1000, // Autoplay speed in milliseconds
        mouseDrag :false,
        rewind:true
    };


return(
    <>
         <OwlCarousel
                            responsiveClass
                            className={"owl-theme banner-slider-modular-jaipur p-0 vw-100 " + props.carouselClass}
                            {...options}
                        >
                        {
                            props.slides.map((modular_obj) => (

                                        <div className=" item p-0 ">
                                            <div className='banner-item'>
                                                <div className={'banner-background back-cover back-bottom ' + modular_obj.back_position} style={{ backgroundImage: `url(${modular_obj.background})` }}>
                                                
                                                <img
                                                src={modular_obj.background}
                                                srcSet={`${modular_obj.back_position} 320w, ${modular_obj.back_position} 453w, ${modular_obj.back_position} 579w, ${modular_obj.background} 687w, ${modular_obj.background} 786w, ${modular_obj.background} 885w, ${modular_obj.background} 975w, ${modular_obj.background} 990w, ${modular_obj.background} 1024w, ${modular_obj.background} 1280w`}
                                                sizes='100vw'
                                                className='w-100 d-block d-md-none'
                                                alt='Your Image Alt Text'
                                                />
                    
                                                </div>
                                                <div className='banner-content-wrapper'>
                                                    <div className='banner-content px-md-5'>
                                                        {modular_obj.title ? <h1 className='banner-title'>{modular_obj.title}</h1> : '' }
                                                        {modular_obj.h2_title ? <h2 className='banner-title '>{modular_obj.h2_title}</h2> : '' }
                                                        {modular_obj.noneh_title ? <div className='banner-title h1 fw-bolder'>{modular_obj.noneh_title}</div> : '' }
                                                        { modular_obj.sub_title ? <p className='banner-subtitle'>{modular_obj.sub_title}</p> : ''} 
                                                    </div>
                                                    <Link to='/contact-us' className={'btn hire-us radius-50 my-3 banner-button ' + props.btnClass}>BOOK FREE CONSULTATION</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                           
                            
                        </OwlCarousel>
    </>
);

};

export default BannerSlider;