import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import cities_icon from '../../asset/images/modular-page-assets/office-building.png'
import list_icon from '../../asset/images/modular-page-assets/briefing.png'
import franchise_icon from '../../asset/images/modular-page-assets/franchise.png'
import cowork_icon from '../../asset/images/modular-page-assets/coworking.png'
import layer_icon from '../../asset/images/modular-page-assets/layer_58.png'


const Counter_section = () => {

    return (
    <>
<section className="stats-counter-section">
<div className="container py-5 py-md-2">
    <div className="stats-counter-wrapper">
        <div className="stat-counter">
            <div className="stats-icon">
                <img src={cities_icon} />
            </div>
            <h2>
                <CountUp end={50} redraw={false}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
                +
            </h2>

            <h5>Cities</h5>
        </div>
        <div className="stat-counter">
            <div className="stats-icon">
                <img src={list_icon} />
            </div>
            <h2>
                <CountUp end={10} redraw={false}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
                K+
            </h2>

            <h5>Projects</h5>
        </div>
        <div className="stat-counter">
            <div className="stats-icon">
                <img src={franchise_icon} />
            </div>
            <h2>
                <CountUp end={10} redraw={false}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
                +
            </h2>

            <h5>Franchise</h5>
        </div>
        <div className="stat-counter">
            <div className="stats-icon">
                <img src={cowork_icon} />
            </div>
            <h2>
                <CountUp end={500} redraw={false}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
                +
            </h2>

            <h5>Team Members</h5>
        </div>
        <div className="stat-counter">
            <div className="stats-icon">
                <img src={layer_icon} />
            </div>
            <h2>
                <CountUp end={5} redraw={false}>
                    {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
                K+
            </h2>

            <h5>Happy Clients</h5>
        </div>
    </div>
</div>
</section>
</>
);
                    }

                    export default Counter_section;