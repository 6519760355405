import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <>
      <section className="container pt-5 ">
        <div className="jumbotron text-center mt-5 pt-5">
            <div className="p-5">

         
          <h1 className="display-3">Thank You!</h1>
          <p className="lead">
            <strong>Thank you for reaching out and expressing interest in our services.</strong>{" "}
          </p>
          <hr />
          <p>
            Having trouble?{" "}
            <Link to="/contact-us">Contact us</Link>
          </p>
          <p className="lead">
            <Link
              className="btn btn-primary btn-sm"
              to="/"
              role="button"
            >
              Continue to homepage
            </Link>
          </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
