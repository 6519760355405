import React, { Link } from 'react';
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-2.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-1.png'

import FeedBack from '../FeedBack';
import FeedbackOwl from '../FeedbackOwl';


const CustomerSection = () => {

    return (
        <>
            <section className="customers-section  py-md-5 py-3 bg-white back-layer-container">
                <div className='back-layer-1 d-none d-md-block'>
                    <img className='img-fluid' src={customer_layer_1} />
                </div>
                <div className='back-layer-2 d-none d-md-block'>
                    <img className='img-fluid' src={customer_layer_2} />
                </div>
                <div className="container">
                    <div className="row justify-content-center justify-content-md-start">
                        <div className="col-md-12 mb-md-4">
                            <h2 className="fw-bold mb-md-4 text-center">
                                 Few Words from Our Satisfied Customers
                            </h2>

                        </div>
                        <div className="col-md-12 mb-md-4 d-md-none">
                         
                               <FeedbackOwl></FeedbackOwl>
                            
                        </div>
                        <div className="row align-items-stretch d-none d-md-flex">
                            <FeedBack/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CustomerSection;