import Path54379 from '../../asset/images/modular-kitchen/Path 54379.svg'
import Faq2 from '../../components/Faq2';
import  { useState } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import BannerSlider from '../../components/BannerSlider';
import Counter_section from '../../components/Static-Section/Counter-section';
import BasicGrid from "../../components/Static-Section/BasicGrid";
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-1.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-2.png'
import WhyChoose from "../../components/Static-Section/whyChoose";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Calculate_now from '../../components/Static-Section/Calculate_section';
import Icons8Bill64 from "../../asset/images/icons/service-1/icons8-bill-64.png";
import Icons8Bill96 from "../../asset/images/icons/service-1/icons8-comments-96.png";
import Showroom2 from "../../asset/images/icons/service-1/showroom-(2).png";
import Icons8MoneyTransfer96 from "../../asset/images/icons/service-1/icons8-money-transfer-96 (1).png";
import Icons8StudioFloorPlan48 from "../../asset/images/icons/service-1/icons8-studio-floor-plan-48.png";
import Icons8CallMale100 from "../../asset/images/icons/service-1/icons8-call-male-100.png";
import Value_section from '../../components/Static-Section/Values_Section';
import Icons8FillinForm96 from "../../asset/images/icons/service-1/icons8-fill-in-form-96.png";
import CustomerSection from '../../components/Static-Section/CustomerSection';
import CityContact from '../../components/CityContact';
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'

const MoudlarInteriros =() =>{

  const options = {
    items: 1, // Number of items to show
    loop: true, // Enable looping
    autoplay: true, // Enable autoplay
    autoplaySpeed: 500, // Autoplay speed in milliseconds
  };
  const [activeData, setActiveData] = useState("Data 1");
  const categorySlug = "full-home-interiors";
  const handleButtonClick = (data) => {
    setActiveData(data);
  };


  const slides = [
    {
      'id': 1,
      'title': 'Modular Interior Solutions in India',
      'sub_title': "Aruwa is a leading pioneer in providing modular interior solutions in India, giving practical and exquisite modular interior designs. ",
      'background': '../../asset/images/banner/modular-service/modular-service-1.png'
    },
    {
      'id': 2,
      'h2_title': 'Modular Interior Solutions in India',
      'sub_title': "Aruwa is a leading pioneer in providing modular interior solutions in India, giving practical and exquisite modular interior designs.",
      'background': '../../asset/images/banner/modular-service/modular-service-2.png'
    },
  ];

  const BasicGrid_content = [
    {
      'id': 1,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/Parallel_Modular_Kitchen.png',
      'grid_title': ' Modular Kitchen'
    },
    {
      'id': 2,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/modular-design/wardrobes.png',
      'grid_title': 'Modular Wardrobe'
    },
    {
      'id': 3,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/master-bedroom.png',
      'grid_title': ' Modular Bedroom'
    },
    {
      'id': 4,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/modular-design/tv-panel.png',
      'grid_title': ' Modular TV Panel'
    },
    {
      'id': 5,
      'grid_image_url': process.env.PUBLIC_URL +'/asset/images/modular/book-shelf.png',
      'grid_title': 'Modular Book Shelf'
    },
    {
      'id': 6,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/hospitality.png',
      'grid_title': ' Other Modular Solutions'
    },
  ];

  const whychoose = {
    'headering_before': 'Why to Choose Aruwa for Your ',
    'heading_yellow': 'Modular Interior ',
    'heading_after': 'Solutions in India?  ',
    'subtitle': 'Aruwa offers modular furniture designs to furnish homes, offices and commercial places for style and comfort. We maintain a customer-centric approach to our modular design furniture, enabling us to fulfill the various demands of our customers.',
    'features': [
      {
        'title': 'Design Experts',
        'desc': "Our experienced teams of designers at Aruwa are well-versed with the latest trends and technologies in modular furniture styles to adjust to the customer’s requirements."
      },
      {
        'title': 'Quality Products',
        'desc': "We only deal with products that are of high quality and superior finish. Thus, our products are durable and easy to maintain for lifelong usage."
      },
      {
        'title': 'Quality Checks',
        'desc': "Aruwa ensures every modular component undergoes a rigorous quality check before installation, ensuring the components do not have any defects."
      },
      {
        'title': 'Excellent Customer Support',
        'desc': 'We at Aruwa have a customer-driven approach making sure that our customers are satisfied. We also have a user-friendly website and a well-dedicated customer support service.'
      },
      {
        'title': 'Timely Project Delivery',
        'desc': "Aruwa values customers time and thus delivers the modular furniture within the committed deadline. Our delivery team coordinates with the designers and the carpenters for smooth project delivery."
      },
      {
        'title': 'Streamlined Procedures',
        'desc': "Aruwa follows a streamlined and transparent procedure for every modular furniture project, from start to finish."
      },
    ]
  };

    return (
        <>
        <Helmet>
                    <title>
                    Modular Interior Solution in India | Aruwa Interiors 
                    </title>
                    <meta
                        name="description"
                        content="Aruwa is a leading provider of modular interior solutions in India. 
We design, install, and maintain customized interiors for homes and offices."
                    />
                </Helmet>
            <BannerSlider slides={slides} />

          
            <section className="banner-content-mb">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>Modular Interior Solutions in India</h1>
                                        <p className='banner-subtitle mb-0'>Aruwa is a leading pioneer in providing modular interior solutions in India, giving practical and exquisite modular interior designs. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            <Counter_section/>

            <section className=" py-md-5 py-3 text-dark bg-white back-layer-container">
        <div className='back-layer-1 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_1} />
        </div>
        <div className='back-layer-2 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_2} />
        </div>
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-12 mb-4 text-black text-center">
              <h2 className="fw-bold mb-md-4">
              Different Modular <span className="yellow-text">Interior Solutions</span> We Provide 
              </h2>
              <p>We have been providing custom modular interior solutions since 2014 and have established ourselves as a leading solution provider for all the interior modular furniture needs. We believe that modular furniture designs allow customising furniture according to the space, need and preferences.</p>
            </div>
            <BasicGrid grid_items={BasicGrid_content} small={6}></BasicGrid>
          </div>
        </div>
      </section>

      <WhyChoose data={whychoose} />

      <Aruwa_edge/>

      <Calculate_now page_title={'Get Estimate for Modular Interiors'} />

                <section className=" py-md-5 py-3 mt-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="fw-bold text-center">
              Start your Modular Interior Journey with Aruwa
              </h2>
              {/* <p>Creating the space of your dreams</p> */}
            </div>
          </div>
        </div>
      </section>
      {/* start with booking */}
      <section className="container-fluid">
        <div className="row mb-5">
          <div className="col d-flex justify-content-center">
          {activeData && (
          <div className="timeline-image">
                {activeData === "Data 1" && (
              <img src='../../asset/images/modular/timeline-1.png' className="img-fluid" />
                )}
                {activeData === "Data 2" && (
              <img src='../../asset/images/modular/timeline-2.png' className="img-fluid" />
                )}
                {activeData === "Data 3" && (
              <img src='../../asset/images/modular/timeline-3.png' className="img-fluid" />
                )}
                {activeData === "Data 4" && (
              <img src='../../asset/images/modular/timeline-4.png' className="img-fluid" />
                )}
          </div>
          )}
            <div
              className="timeline-steps aos-init aos-animate"
              data-aos="fade-up"
            >
              <div
                className={`timeline-step`}
                onClick={() => handleButtonClick("Data 1")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2003"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 1" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 1" ? "text-black" : ""
                      }`}
                  >
                    Start With Booking
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 2")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2004"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 2" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 2" ? "text-black" : ""
                      }`}
                  >
                    Get Design Ready
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 3")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2005"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 3" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 3" ? "text-black" : ""
                      }`}
                  >
                    Project Implementation
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 4")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2010"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 4" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 4" ? "text-black" : ""
                      }`}
                  >
                    Project Delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeData && (
          <div>
            {activeData === "Data 1" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Start with Booking</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Fill out the form to give your basic details
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            You will get a callback from our team
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            Share your floor plan with us
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Get the proposed quotation according to your design
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Showroom2} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Visit Our Showroom or connect via online video call
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8Bill96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Finalize the total project cost
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8Bill64} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Pay 5% of the total project cost and confirm your
                            booking
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 2" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Get Design Ready</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            We will plan a site visit for final measurement
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Get your detailed design prototype ready with colour
                            material & 3D model
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            {" "}
                            Revised the drawing & quotation as per your review.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Pay 50% of the total project amount for raising
                            purchase order
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 3" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Project Implementation</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Track the progress of your order
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Our Operation team will get the confirmation of the
                            required civil work.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            You will be notified as your order is ready for
                            dispatch.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Pay 100% of the total amount for the dispatch of the
                            material.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Showroom2} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Material will be installed as per the design.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 4" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5> Project Delivery</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Site cleaning will be done
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Your feedback will be taken on site handover
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">Get your invoice via mail</div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Keep your invoice to claim warranty.
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 p-4">
                      <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                        <div className="col-3">
                          {" "}
                          <img src={Showroom2} alt="" />{" "}
                        </div>
                        <div className="col-9">Keep your invoice to claim warranty.</div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        )}
      </section>

      <Value_section/>

      <CustomerSection/>

      <CityContact/>

      <section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
        <div className="test-layer-1">
          <img src={test_item_1} className="img-fluid" />
        </div>
        <div className="test-layer-2">
          <img src={test_item_2} className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
              <Faq2 categorySlug={"faq-service-modular-interior"} ></Faq2>
            </div>
          </div>
        </div>
      </section>

        </>
    )
}
export default MoudlarInteriros;