import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import config from "../config";
import ReactGA from "react-ga";
import { Link } from 'react-router-dom';
import Counter_section from "../components/Static-Section/Counter-section";
import Aruwa_edge from "../components/Static-Section/Aruwa_Edge_Table";
import Value_section from "../components/Static-Section/Values_Section";
import Calculate_now from "../components/Static-Section/Calculate_section";
import CustomerSection from "../components/Static-Section/CustomerSection";
import icici from '../asset/images/modular-page-assets/icici.jpg'
import contact_banner from '../asset/images/contact-new/contact-banner.png'


const Contact_2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getCity, setGetCity] = useState([]);
  const [showOtherCityInput, setShowOtherCityInput] = useState(false);

  useEffect(() => {
    fetch(`${config.base_url}getCity`)
      .then((res) => res.json())
      .then(
        (result) => {
          setGetCity(result.cities);
        },
        (error) => {

        }
      );
  }, []);


  useEffect(() => {
    ReactGA.initialize("G-FX78VTMXRH");
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormData({ ...formData, utm: urlParams.get('utm_campaign') ?? '' });
  }, []); 

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    city: "",
    otherCity: "",
    message: "",
    interests: [],
    utm: [],
  });


  const handleOnCheckboxChange = (e) => {

    setFormData((prevFormData) => {

      if (e.target.checked) {
        return { ...prevFormData, interests: [...prevFormData.interests, e.target.value] };
      } else {
        const updatedInterests = prevFormData.interests.filter((interest) => interest !== e.target.value);
        return { ...prevFormData, interests: updatedInterests };
      }

    });
  }

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErroMessage] = useState("");
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.value === 'other') {
      setShowOtherCityInput(true);
    }
    getCity.map((data) => {
      if (e.target.value === data.name) {
        setShowOtherCityInput(false);
      }
    })
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage(null);
    fetch(`${config.base_url}contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => res.json())
      .then((response) => {
        if (response) {
          if (response.message === 'success') {
            navigate('/thankyou')
            setErroMessage(null)
            setSuccessMessage("Form submitted successfully");
            setFormData({
              name: "",
              mobile: "",
              email: "",
              city: "",
              message: "",
              otherCity: "",
              interests: [],
              utm: []
            });
          } else {
            setErroMessage(response.message);
          }

          ReactGA.event({
            category: "enquiry form",
            action: "submited",
            label: "Contact Form Submitted",
          });
        } else {
          throw new Error("Form submission failed");
        }
      })
      .catch((error) => {
        console.error("Form submission failed:", error);
      })
      .finally(() => {
        setIsLoading(false); // Reset the loading state regardless of success or failure
      });
  };
  return (
    <>
      <main className="sans-serif-font">
        <div className=" banner-top" style={{ backgroundImage: `url(${contact_banner})` }}>
          <div className="container py-5">
            <div className="row">
              <div className=" col-lg-7 my-5"> </div>
              <div className=" col-lg-5">
                <div className="banner-form-wrapper">
                  <form className="banner-form" onSubmit={handleSubmit}>

                    {successMessage && (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}

                    <div className="row">
                      <div className="col-12 px-0 px-lg-0 text-center">
                        <h4>You Are One Click Away</h4>
                        <p>Get your <span className="yellow-text">dream home</span> today. Let our experts help you</p>
                      </div>
                      <div className="col-12 px-0 px-lg-0 mb-2">
                        <input type="text" className="form-control" id="name" name="name" placeholder="Name*"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="col-12 px-0 px-lg-0 mb-2">
                        <input type="tel" className="form-control" id="mobile" name="mobile" placeholder="Mobile Number*"
                          value={formData.mobile}
                          onChange={handleInputChange}
                          required />
                      </div>
                      <div className="col-12 px-0 px-lg-0 mb-2">
                        <input type="email" className="form-control" id="email" name="email" placeholder="Email*" aria-describedby="emailHelp"
                          value={formData.email}
                          onChange={handleInputChange}
                          required />
                      </div>
                      <div className="col-12 px-0 px-lg-0 mb-2">
                        <input type="text" className="form-control" id="city" name="city" placeholder="Location*"
                          value={formData.city}
                          onChange={handleInputChange}
                          required />
                      </div>
                      <div className="col-12 px-0 px-lg-0 mb-2">
                        <textarea className="form-control" name="message"
                          id="message"

                          value={formData.message}
                          onChange={handleInputChange}
                          required
                          rows="3" placeholder="Message.."></textarea>
                      </div>
                      <div className=" col-12 col-md-6 mb-2 form-check">
                        <label className="form-check-label"htmlFor="exampleCheck1">MODULAR KITCHEN</label>
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" value='Modular Kitchen' name="interests"
                          onChange={handleOnCheckboxChange}
                        />
                      </div>
                      <div className=" col-12 col-md-6 mb-2 form-check">
                        <label className="form-check-label"htmlFor="exampleCheck1">HOME INTERIOR</label>
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" value='Home Interior' name="interests" onChange={handleOnCheckboxChange} />
                      </div>
                      <div className=" col-12 col-md-6 mb-2 form-check">
                        <label className="form-check-label"htmlFor="exampleCheck1">MODULAR FURNITURE</label>
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" value='Modular Furniture' name="interests" onChange={handleOnCheckboxChange} />
                      </div>
                      <div className=" col-12 col-md-6 mb-2 form-check">
                        <label className="form-check-label"htmlFor="exampleCheck1">COMMERCIAL INTERIOR</label>
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" value='Commercial Interior' name="interests" onChange={handleOnCheckboxChange} />
                      </div>
                      <div className=" col-12 col-md-6 mb-2 form-check">
                        <label className="form-check-label"htmlFor="exampleCheck1">OTHERS</label>
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" value='Others' name="interests" onChange={handleOnCheckboxChange} />
                      </div>
                      <div className="col-8 text-center mx-auto justify-self-center">
                        <button type="submit" className="btn hire-us radius-50 my-3">SUBMIT NOW</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <Counter_section></Counter_section>

        <Aruwa_edge></Aruwa_edge>

        <Value_section></Value_section>

        <section className="pay-later d-none">
          <div className="container py-2 p-md-5">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>Design now, Pay later</h2>
                <p>Are you postponing your home interior project due to a financial crisis? You don’t have to worry anymore.</p>
                <div className="paylater-icon-wrapper">
                  <div className="paylater-icon">
                    <img className="img-fluid" src={icici} />
                  </div>
                  <div className="paylater-icon">
                    <img className="img-fluid" src={icici} />
                  </div>
                  <div className="paylater-icon">
                    <img className="img-fluid" src={icici} />
                  </div>
                </div>
                <div className="mb-4">
                  <h5>Tenure Period</h5>
                  <p>12 months - 63 months</p>
                </div>
                <div>
                  <h5>T&C Apply</h5>
                  <p>It is recommended to speak to an authorized Aruwa representative to get an understanding of the current EMI schemes being followed| Aruwa does not commit to any loan approvals| The loan approvals are solely dependent on your particular credit score and as the norms guided by the above mentioned financial companies| If you are eligible for the loan approval then 50% would be charged at the payment stage.</p>
                  <a href="#" className="btn hire-us radius-50 my-3 text-uppercase">Get Started Now</a>
                </div>

              </div>
            </div>
          </div>
        </section>

        <CustomerSection></CustomerSection>

        {/*  */}
        <section className="container-fluid py-5">
          <section className="container">
            <div className="row">
              <div className="col-lg-6 p-0 d-none d-md-block text-left">
                <div className=" h-100">
                  <h4 className="display-6 fw-bold mb-4">
                    Signup to our newsletter
                  </h4>
                  <h5 className="fw-lighter">
                    Stay up to date with the latest news and articles.
                  </h5>
                </div>
              </div>
              <div className="col-lg-6 px-md-1 py-md-3 p-4">
                <form className="newsletter-form">
                  <div className="row">
                    <div className="col-8">
                      <input
                        type="email"
                        className="form-control h-100 border-dark "
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="col-4">
                      <button
                        type="submit"
                        className="btn btn-dark text-capitalize px-md-5 py-md-3 "
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </section>
      </main>
    </>
  );
};

export default Contact_2;
