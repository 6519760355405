import React, { useState } from "react";
import Faq2 from "../../components/Faq2";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'
import BannerSlider from "../../components/BannerSlider";
import Counter_section from "../../components/Static-Section/Counter-section";
import BasicGrid from "../../components/Static-Section/BasicGrid";
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-2.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-1.png'
import WhyChoose from "../../components/Static-Section/whyChoose";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Calculate_now from "../../components/Static-Section/Calculate_section";
import Value_section from "../../components/Static-Section/Values_Section";
import CustomerSection from "../../components/Static-Section/CustomerSection";
import CityContact from "../../components/CityContact";
import TransformSpace from "../../components/Static-Section/TransformSpace";

const FullHomeInteriors = () => {


  const slides = [
    {
      'id': 1,
      'title': 'Top-Notch Home Interior Design Services in India',
      'sub_title': "As the premier choice for Home Interior Design in India, we’re dedicated to cater to all your modern home designing needs.",
      'background': process.env.PUBLIC_URL+'/asset/images/banner/commercial/service-interior-2.png'
    },
    {
      'id': 2,
      'h2_title': 'Top-Notch Home Interior Design Services in India',
      'sub_title': "As the premier choice for Home Interior Design in India, we’re dedicated to cater to all your modern home designing needs.",
      'background': process.env.PUBLIC_URL+'/asset/images/banner/commercial/service-interior-1.png'
    },
  ];


  const BasicGrid_content = [
    {
      'id': 1,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/living_room.png',
      'grid_title': 'Living Room Design'
    },
    {
      'id': 2,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/master-bedroom.png',
      'grid_title': 'Bedroom Design'
    },
    {
      'id': 3,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/kitchen-designs/Island_Modular_Kitchen.png',
      'grid_title': 'Kitchen Design'
    },
    {
      'id': 4,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/bathroom.png',
      'grid_title': 'Bathroom Design'
    },
    {
      'id': 5,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/home-interior-design/pooja-room.png',
      'grid_title': 'Pooja Room Design'
    },
    {
      'id': 6,
      'grid_image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/modular-design/wardrobes.png',
      'grid_title': 'Wardrobe & Cabinet Design'
    },
  ];

  const whychoose = {
    'headering_before': 'Why Choose Aruwa Full ',
    'heading_yellow': 'Home Decorator ',
    'heading_after': 'Services for your Home?',
    'subtitle': 'Choosing Aruwa Full Home Interior Decorator Services for your home is a decision you will always cherish. We take utmost pride in our exceptional craftsmanship and guarantee that every aspect of your home interior is designed and executed for perfection.',
    'features': [
      {
        'title': 'Unmatched Expertise',
        'desc': "We take pride in our unparalleled expertise in home design, ensuring that every aspect of the space is designed and executed flawlessly."
      },
      {
        'title': 'Personalized Services',
        'desc': "We offer exceptional home interior solutions that will cater to your distinct tastes and ensure that your home mirrors your style and requirements."
      },
      {
        'title': 'Cutting-Edge Designs',
        'desc': "Aruwa’s home interiors are renowned for their cutting-edge designs by staying updated with the latest trends and technologies to deliver an effective living space."
      },
      {
        'title': 'Efficient Execution',
        'desc': "Our experienced team ensures a smooth and precise execution process to provide you with a fully functional and aesthetically pleasing home"
      },
      {
        'title': 'Superior Quality',
        'desc': "We use only premium quality materials such as robust furniture, fixtures, and hardware to ensure the durability and functionality of home interiors."
      },
      {
        'title': 'Optimal Space Utilization',
        'desc': "Our Interior Design for Home services is focused on maximizing space utilization to extract the most of every corner of your home irrespective of the size."
      },
    ]
  };


  return (
    <>

<Helmet>
                    <title>
                    Home Interior Design Services in India | Home Decorator Services
                    </title>
                    <meta
                        name="description"
                        content="ARUWA offers the best home interior design services in India. 
Whether you need a complete makeover or a quick refresh, 
our home decorator services will transform your space into your dream home. "
                    />
                </Helmet>
      <BannerSlider slides={slides} />

      <section className="banner-content-mb">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>Top-Notch Home Interior Design Services in India</h1>
                                        <p className='banner-subtitle mb-0'>As the premier choice for Home Interior Design in India, we’re dedicated to cater to all your modern home designing needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

      <Counter_section></Counter_section>

      <section className=" py-md-5 py-3 text-dark bg-white back-layer-container">
        <div className='back-layer-1 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_1} />
        </div>
        <div className='back-layer-2 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_2} />
        </div>
        <div className="container">
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-md-12 mb-4 text-black text-center">
              <h2 className="fw-bold mb-md-4">
                Offering Modern <span className="yellow-text">Home Decorator </span> Services
              </h2>
              <p className="text-justify text-md-center">We present an extensive range of home interior decorator services that are tailored to fit customers’ budgets and requirements. Our services ensure every part of the home is utilised for utmost aesthetic appeal and usability.</p>
            </div>
            <BasicGrid grid_items={BasicGrid_content} small={6}></BasicGrid>
          </div>
        </div>
      </section>

      <WhyChoose data={whychoose} />
      
      <TransformSpace />

      <Aruwa_edge></Aruwa_edge>

      <Calculate_now page_title={'Ready to Transform Your Home with us'} />

      <Value_section />

      <CustomerSection />

      <CityContact />

      <section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
        <div className="test-layer-1">
          <img src={test_item_1} className="img-fluid" />
        </div>
        <div className="test-layer-2">
          <img src={test_item_2} className="img-fluid" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0">
              <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
              <Faq2 categorySlug={"full-home-interiors"} ></Faq2>
            </div>
          </div>
        </div>
      </section>



    </>
  );
};

export default FullHomeInteriors;
