import React from 'react';

const   NotFound = () =>  {
  return (
    <div className='container text-center pt-5'>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      {/* You can add a link to the homepage or another page */}
      <a href="/">Go to Homepage</a>
    </div>
  );
}

export default NotFound;
