import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import config from "../config";
import ReactGA from "react-ga";
import form_image from '../asset/images/modular-page-assets/form-image.jpg'

const CityContact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getCity, setGetCity] = useState([]);
  const [showOtherCityInput, setShowOtherCityInput] = useState(false);

  useEffect(() => {
    fetch(`${config.base_url}getCity`)
      .then((res) => res.json())
      .then(
        (result) => {
          setGetCity(result.cities);
        },
        (error) => {

        }
      );
  }, []);



  useEffect(() => {
    ReactGA.initialize("G-FX78VTMXRH");
  }, []);

  const navigate = useNavigate();



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormData({ ...formData, utm: urlParams.get('utm_campaign') ?? '' });
  }, []); 

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    city: "",
    otherCity: "",
    message: "",
    interests: [],
    utm: []
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErroMessage] = useState("");
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value === 'other') {
      setShowOtherCityInput(true);
    }
    getCity.map((data) => {
      if (e.target.value === data.name) {
        setShowOtherCityInput(false);
      }
    })

  };

  const handleOnCheckboxChange = (e) => {

    setFormData((prevFormData) => {

      if (e.target.checked) {
        return { ...prevFormData, interests: [...prevFormData.interests, e.target.value] };
      } else {
        const updatedInterests = prevFormData.interests.filter((interest) => interest !== e.target.value);
        return { ...prevFormData, interests: updatedInterests };
      }

    });
  }

  



  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSuccessMessage(null);
    fetch(`${config.base_url}contact-us`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => res.json())
      .then((response) => {
        if (response) {
          if (response.message === 'success') {
            navigate('/thankyou')
            setErroMessage(null)
            setSuccessMessage("Form submitted successfully");
            setFormData({
              name: "",
              mobile: "",
              email: "",
              city: "",
              message: "",
              otherCity: "",
              interests:[],
              utm: []
            });
          } else {
            setErroMessage(response.message);
          }

          ReactGA.event({
            category: "enquiry form",
            action: "submited",
            label: "Contact Form Submitted",
          });
        } else {
          throw new Error("Form submission failed");
        }
      })
      .catch((error) => {
        console.error("Form submission failed:", error);
      })
      .finally(() => {
        setIsLoading(false); // Reset the loading state regardless of success or failure
      });
  };
  return (
    <>
          <section className="modular-form-section " id="scrollSection" >
                    <div className="container-fluid p-0">
                        <div className="form-container align-items-end">
                            <div className="left-side text-end p-0">
                                <img
                                    src={form_image}
                                    alt="" className="img-fluid"
                                />
                            </div>
                            <div className="right-side bg-color-gray ps-md-5 py-4 padding-md-right p-md-5">
                                <h2 className="text-center fw-bold mb-md-4">
                                    You Are One Click Away
                                </h2>
                                <h5 className="text-center">Get your dream home today. Let our experts help you</h5>
                                <form className="enquiry-form text-dark" onSubmit={handleSubmit}>

                                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                        )}
                                        {errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                        )}

                                    <div className="row justify-content-md-start justify-content-center">
                                        <div className="mb-3 col-12">

                                            <input
                                                type="text"
                                            
                                                className="form-control"
                                                placeholder="Full Name*"
                                                id="name" name="name" 
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-12">

                                            <input
                                                className="form-control"
                                                type="text"
                                                id="mobile" name="mobile" placeholder="Mobile Number*" 
                                                value={formData.mobile}
                                                  onChange={handleInputChange}
                                                  required
                                            />
                                        </div>
                                        <div className="mb-3 col-12">

                                            <textarea
                                                
                                                className="form-control input-alike"
                                                placeholder="Email*"
                                                id="email"
                                                name="email" 
                                                
                                                aria-describedby="emailHelp" 
                                              value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="mb-3 col-12">

                                            <input
                                                className="form-control"
                                                type="text"
                                                id="city" name="city" placeholder="Location*" 
                                                value={formData.city}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-12">

                                            <textarea
                                                className="form-control"
                                                name="message"
                                                id="message"
                                                 
                                                  value={formData.message}
                                                  onChange={handleInputChange}
                                                  required
                                                  placeholder="Message.."
                                            ></textarea>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="form-check">

                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox" className="form-check-input"
                                                        value='Modular Kitchen'
                                                        name="interests" onChange={handleOnCheckboxChange}
                                                    />
                                                    MODULAR KITCHEN
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="form-check">

                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox" className="form-check-input"
                                                        value='Home Interior'
                                                        name="interests" onChange={handleOnCheckboxChange}
                                                    />
                                                    HOME INTERIOR
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="form-check">

                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox" className="form-check-input"
                                                         value='Modular Furniture'
                                                        name="interests" onChange={handleOnCheckboxChange}
                                                    />
                                                    MODULAR FURNITURE
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="form-check">

                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox" className="form-check-input"
                                                        value='Commercial Interior'
                                                        name="interests" onChange={handleOnCheckboxChange}
                                                    />
                                                    COMMERCIAL INTERIOR
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-3">
                                            <div className="form-check">

                                                <label className="form-check-label">
                                                    <input
                                                        type="checkbox" className="form-check-input"
                                                         value='Others'
                                                        name="interests" onChange={handleOnCheckboxChange}
                                                    />
                                                    OTHERS
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12  text-center">
                                            <button
                                                type="submit"
                                                className="btn-yellow text-capitalize px-md-5 py-2 rounded-0"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? "Sending..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
    </>
  );
};

export default CityContact;
