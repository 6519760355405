
import React, { useState ,useEffect} from 'react';
import Spinner from "../components/Spinner/Spinner";
import config from "../config";
import Moment from 'moment';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

const FeedBack =() =>{

  const url = window.location.pathname;

      const segments = url.split('/');
    const lastSegment = segments[segments.length - 1];

  //  console.log(lastSegment);

    const uploadUrl = `${config.base_uploadUrl}post_type_pages/`;
    
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [feedbackData, setFeedback] = useState([]);
    const [videoId     ,setVideoID] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        fetch(`${config.base_url}feedback?page_url=${lastSegment}`)
          .then((res) => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setFeedback(result);
            },
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          );
      }, []);

      let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal(video_id) {
    setVideoID(video_id);
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';

  }

  //  owl option


  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      zIndex : '999999'
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',

    },
  };
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (!isLoaded) {
        return (
          <div>
            <Spinner />
          </div>
        );
      } else {

    return(<>
  


      {feedbackData.feedback.map((data,index) => {
          return(
            <div className="col-md-4 p-2 item">
            <div className="bg-light p-3 h-100">
            <div className="flex flex-column">
                <div className="col-md-12   position-relative col-12 p-0 text-center mb-3">
                {
                     data.show_on_home ===1  ? 
                 (
                        <iframe className='iframe-responsive '  src={`https://www.youtube.com/embed/${data.iframe_url}`} title="YouTube video player" frameBorder="0" allow="accelerometer;  clipboard-write; encrypted-media; picture-in-picture;" allowFullScreen  ></iframe>
                 ):
                      (  <>
                        <img
              //  onClick={() => openModal(data.iframe_url)}
                   src={process.env.PUBLIC_URL + '/asset/images/icons/Polygon 6.svg'}
                   className="position-absolute play-icon d-none"
                   alt=""
               />
                  <img
                   
                   src={`${uploadUrl}${data.featured_image}`}
                   className="w-100"
                   alt={`${data.alt_text}`}
               />
                 </>)
                    
                     }
                   
                </div>
                {
                  data.show_on_home===1 ? (<>
                           <div className="col-md-12 col-12 p-0 text-center text-md-start">
                          <h5>{data.title}</h5>
                           </div>
                  </>) 
                  :(
                    <>
                      <div className="col-md-12 col-12 p-0 text-center text-md-start">
                    <h5>{data.title}</h5>
                    <div dangerouslySetInnerHTML={{ __html: data.content }} />
                    </div>
                    </>
                  )
                }
            </div>
        </div>
        </div>              

    
                       ) })}

                        <Modal
                          isOpen={modalIsOpen}
                          onAfterOpen={afterOpenModal}
                          onRequestClose={closeModal}
                          style={customStyles}
                          ariaHideApp={false}
                          contentLabel="Example Modal"
                        >
                          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
                          <div><iframe className='iframe-responsive iframe-responsive-for-mobile'  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen  ></iframe></div>
                          <button className='btn' onClick={closeModal}>close</button>
                        </Modal>
    </>)}
}
export default FeedBack