import React, { useState } from "react";
import Faq2 from "../../components/Faq2";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Icons8Bill64 from "../../asset/images/icons/service-1/icons8-bill-64.png";
import Icons8Bill96 from "../../asset/images/icons/service-1/icons8-comments-96.png";
import Showroom2 from "../../asset/images/icons/service-1/showroom-(2).png";
import Icons8MoneyTransfer96 from "../../asset/images/icons/service-1/icons8-money-transfer-96 (1).png";
import Icons8StudioFloorPlan48 from "../../asset/images/icons/service-1/icons8-studio-floor-plan-48.png";
import Icons8CallMale100 from "../../asset/images/icons/service-1/icons8-call-male-100.png";
import Icons8FillinForm96 from "../../asset/images/icons/service-1/icons8-fill-in-form-96.png";
import BannerSlider from "../../components/BannerSlider";
import CustomerSection from "../../components/Static-Section/CustomerSection";
import CityContact from "../../components/CityContact";
import Value_section from "../../components/Static-Section/Values_Section";
import Calculate_now from "../../components/Static-Section/Calculate_section";
import Aruwa_edge from "../../components/Static-Section/Aruwa_Edge_Table";
import Counter_section from "../../components/Static-Section/Counter-section";
import customer_layer_1 from '../../asset/images/modular-page-assets/customer-layer-1.png'
import customer_layer_2 from '../../asset/images/modular-page-assets/customer-layer-2.png'
import test_back from '../../asset/images/modular-page-assets/testimonial.jpg'
import test_item_1 from '../../asset/images/modular-page-assets/test-item-1.png'
import test_item_2 from '../../asset/images/modular-page-assets/test-item-2.png'
import WhyChoose from "../../components/Static-Section/whyChoose";


const CommercialInteriors = () => {
  const options = {
    items: 1, // Number of items to show
    loop: true, // Enable looping
    autoplay: true, // Enable autoplay
    autoplaySpeed: 500, // Autoplay speed in milliseconds
  };
  const [activeData, setActiveData] = useState("Data 1");
  const categorySlug = "full-home-interiors";
  const handleButtonClick = (data) => {
    setActiveData(data);
  };


  const slides = [
    {
      'id': 1,
      'title': 'Best-in-Class Commercial Interior Designer in India ',
      'sub_title': "Aruwa is the best provider of commercial interior design services in India, for commercial spaces, imparting increased efficiency and functionality.",
      'background': '../../asset/images/banner/commercial/service-commercial-2.png'
    },
    {
      'id': 2,
      'title': '',
      'h2_title': "Best-in-Class Commercial Interior Designer in India ",
      'sub_title': "Aruwa is the best provider of commercial interior design services in India, for commercial spaces, imparting increased efficiency and functionality.",
      'background': '../../asset/images/banner/commercial/service-commercial-1.png'
    },
  ];
  const commercial_interior_design = [
    {
      'id': 1,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/corporate_offices.png',
      'design_title': 'Corporate Offices'
    },
    {
      'id': 2,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/retail-store.png',
      'design_title': 'Retail Store'
    },
    {
      'id': 3,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/hotels.png',
      'design_title': 'Hotels and Restaurants'
    },
    {
      'id': 4,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/warehouses.png',
      'design_title': 'Warehouses'
    },
    {
      'id': 5,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/education-hub.png',
      'design_title': 'Education Hub'
    },
    {
      'id': 6,
      'image_url': process.env.PUBLIC_URL + '/asset/images/design-ideas/corporate-design/hospitality.png',
      'design_title': 'Others'
    },

  ];

  const whychoose = {
    'headering_before': 'Factors that make Aruwa ',
    'heading_yellow': 'Commercial Interior ',
    'heading_after': 'services unique',
    'subtitle': 'Aruwa is a leading commercial interior company that provides an extensive range of exceptional services for commercial interiors to establish long-term professional wellbeing.',
    'features': [
      {
        title: 'Customer Cemtric',
        desc: "As a commercial interior designer, we provide commercial modular services, which are customer-centric and built around the preferences of our clients’ business."
      },
      {
        'title': 'Transparency',
        'desc': "We are solution providers and intend not to hide any cost charges from our clients, as the final charges remain the same as discussed at the time of approval."
      },
      {
        'title': 'Timely Delivery',
        'desc': "Aruwa guarantees to deliver the renovated spaces under the committed time and with professional installation to ensure hassle-free functioning."
      },
      {
        'title': 'Robust Supply Chain',
        'desc': "Aruwa has a self-manufacturing unit, so our inventories never run out with zero compromise in the quality."
      },
      {
        'title': 'Premium Quality Materials',
        'desc': "Aruwa uses only top-quality materials, ensuring that the maintenance costs after installation are of minimal value."
      },
      {
        'title': 'Multiple Quality Checks',
        'desc': "We ensure that installed appliances are of superior quality by subjecting them to multiple quality checks to confirm their smooth functioning."
      },
    ]
  };

  return (
    <>
    <Helmet>
                    <title>
                    Commercial Interior Designer India | Commercial Interior Services
                    </title>
                    <meta
                        name="description"
                        content="Aruwa is a leading commercial interior designer in India, offering innovative and customized solutions for offices, restaurants, hotels, and more."
                    />
                </Helmet>
      <section>
        <div className="container-fluid">
          <div className="row">
            <BannerSlider slides={slides} ></BannerSlider>
          </div>
        </div>
      </section>

      <section className="banner-content-mb">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <div className=' text-center py-3 px-2'>
                                    <div className=' px-md-5'>
                                        <h1 className='banner-title'>Best-in-Class Commercial Interior Designer in India</h1>
                                        <p className='banner-subtitle mb-0'>Aruwa is the best provider of commercial interior design services in India, for commercial spaces, imparting increased efficiency and functionality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

      <Counter_section></Counter_section>

      <section className='modular-furniture-design py-md-5 p-3 bg-design-1 back-layer-container'>
        <div className='back-layer-1 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_1} />
        </div>
        <div className='back-layer-2 d-none d-md-block'>
          <img className='img-fluid' src={customer_layer_2} />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 mb-4 text-center'>
              <h2 className="fw-bold mb-md-4">
                Offering the Best <span className="yellow-text"> Commercial Interior</span> Solutions
              </h2>
              <p>Aruwa is the best choice for transforming commercial interiors into functional and aesthetically pleasing environments, as our services are tailored to fulfill the diverse requirements of each client. Our design team specializes in making interior designs for commercial spaces that enhance their feasibility and visual appeal.</p>
            </div>
            {commercial_interior_design.map((design_obj) => (

              <div className='col-md-4 col-6'>
                <div className='design-card mb-5'>
                  <div className='design-card-icon mb-3'>
                    <img className='img-fluid' src={design_obj.image_url} />
                  </div>
                  <div className='design-card-title text-center'>
                    <h4>{design_obj.design_title}</h4>
                  </div>
                </div>
              </div>
            ))            }
            <Link
              to={"/contact-us"}
              className="btn hire-us my-3 mx-auto"
            >
              BOOK FREE CONSULTATION
            </Link>
          </div>
        </div>
      </section>

      <WhyChoose data={whychoose}/>

      <Aruwa_edge></Aruwa_edge>

      <Calculate_now page_title={'Get Your Commercial Interior Estimates'} />

                <section className=" py-md-5 py-3 mt-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="fw-bold text-center">
              Start your Commercial Interior Journey with Aruwa
              </h2>
              {/* <p>Creating the space of your dreams</p> */}
            </div>
          </div>
        </div>
      </section>
      {/* start with booking */}
      <section className="container-fluid">
        <div className="row mb-5">
          <div className="col d-flex justify-content-center">
          {activeData && (
          <div className="timeline-image">
                {activeData === "Data 1" && (
              <img src='../../asset/images/modular/timeline-1.png' className="img-fluid" />
                )}
                {activeData === "Data 2" && (
              <img src='../../asset/images/modular/timeline-2.png' className="img-fluid" />
                )}
                {activeData === "Data 3" && (
              <img src='../../asset/images/modular/timeline-3.png' className="img-fluid" />
                )}
                {activeData === "Data 4" && (
              <img src='../../asset/images/modular/timeline-4.png' className="img-fluid" />
                )}
          </div>
          )}
            <div
              className="timeline-steps aos-init aos-animate"
              data-aos="fade-up"
            >
              <div
                className={`timeline-step`}
                onClick={() => handleButtonClick("Data 1")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2003"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 1" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 1" ? "text-black" : ""
                      }`}
                  >
                    Start With Booking
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 2")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2004"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 2" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 2" ? "text-black" : ""
                      }`}
                  >
                    Get Design Ready
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 3")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2005"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 3" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 3" ? "text-black" : ""
                      }`}
                  >
                    Project Implementation
                  </p>
                </div>
              </div>
              <div
                className={`timeline-step `}
                onClick={() => handleButtonClick("Data 4")}
              >
                <div
                  className="timeline-content"
                  data-toggle="popover"
                  data-trigger="hover"
                  data-placement="top"
                  title=""
                  data-content="And here's some amazing content. It's very engaging. Right?"
                  data-original-title="2010"
                >
                  <div
                    className={`inner-circle ${activeData === "Data 4" ? "active" : ""
                      }`}
                  ></div>
                  <p
                    className={`h6 mt-3 mb-1 ${activeData === "Data 4" ? "text-black" : ""
                      }`}
                  >
                    Project Delivery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeData && (
          <div>
            {activeData === "Data 1" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Start with Booking</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Fill out the form to give your basic details
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            You will get a callback from our team
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            Share your floor plan with us
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Get the proposed quotation according to your design
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Showroom2} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Visit Our Showroom or connect via online video call
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8Bill96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Finalize the total project cost
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8Bill64} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Pay 5% of the total project cost and confirm your
                            booking
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 2" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Get Design Ready</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            We will plan a site visit for final measurement
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Get your detailed design prototype ready with colour
                            material & 3D model
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            {" "}
                            Revised the drawing & quotation as per your review.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Pay 50% of the total project amount for raising
                            purchase order
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 3" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5>Project Implementation</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Track the progress of your order
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Our Operation team will get the confirmation of the
                            required civil work.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">
                            You will be notified as your order is ready for
                            dispatch.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Pay 100% of the total amount for the dispatch of the
                            material.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Showroom2} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Material will be installed as per the design.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            )}
            {activeData === "Data 4" && (
              <div>
                <section className=" py-md-4 py-3">
                  <div className="container">
                    <div className="row text-center">
                      <h5> Project Delivery</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p> */}
                    </div>
                    <div className="row bg-light py-3 rounded align-items-stretch">
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8FillinForm96} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            {" "}
                            Site cleaning will be done
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8CallMale100} alt="" />{" "}
                          </div>
                          <div className="col-9">
                            Your feedback will be taken on site handover
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8StudioFloorPlan48} alt="" />
                          </div>
                          <div className="col-9">Get your invoice via mail</div>
                        </div>
                      </div>
                      <div className="col-md-4 p-4">
                        <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                          <div className="col-3">
                            {" "}
                            <img src={Icons8MoneyTransfer96} alt="" />
                          </div>
                          <div className="col-9">
                            Keep your invoice to claim warranty.
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-4 p-4">
                      <div className="row bg-white yellow-dotted align-items-center py-2 mx-1 rounded  h-100">
                        <div className="col-3">
                          {" "}
                          <img src={Showroom2} alt="" />{" "}
                        </div>
                        <div className="col-9">Keep your invoice to claim warranty.</div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
        )}
      </section>

      <Value_section></Value_section>

<CustomerSection></CustomerSection>

<CityContact/>
<section className=" py-md-5 py-5 px-3 testimonial-modular-jaipur back-cover" style={{ backgroundImage: `url(${test_back})` }}>
                    <div className="test-layer-1">
                        <img src={test_item_1} className="img-fluid" />
                    </div>
                    <div className="test-layer-2">
                        <img src={test_item_2} className="img-fluid" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <h3 className="mb-3 text-center yellow-text ">Frequently Asked Questions</h3>
                                <Faq2 categorySlug={"commercial-interior"} ></Faq2>
                            </div>
                        </div>
                    </div>
                </section>
    </>
  );
};

export default CommercialInteriors;
