import React from 'react';



const TimelineContent =() =>{

    console.log(process.env.PUBLIC_URL)
    return(
        <>
        <section>
                <div className=" how-it-works container py-5">
                    <div className="row mb-5 justify-content-center">
                        <h2 className="text-start text-md-center mb-4">Journey of Your Home Renovation</h2>
                        <div className="col-12 d-flex justify-content-center mb-3">
                            <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                                <div className="timeline-step">
                                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div className="timeline-icon">
                                            <img src='/asset/images/renovationGrid/timeline/reno-journey-1.png' />
                                        </div>
                                        <p>Meet with Aruwa Designer</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div className="timeline-icon">
                                            <img src='/asset/images/renovationGrid/timeline/reno-journey-2.png' />
                                        </div>
                                        <p>Book a renovation(15% Payment)</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div className="timeline-icon">
                                            <img src='/asset/images/renovationGrid/timeline/reno-journey-3.png' />
                                        </div>
                                        <p>Execution starts(50% Payment)</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div className="timeline-icon">
                                            <img src='/asset/images/renovationGrid/timeline/reno-journey-4.png' />
                                        </div>
                                        <p>Final site installation (100% Payment)</p>
                                    </div>
                                </div>
                                <div className="timeline-step">
                                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="And here's some amazing content. It's very engaging. Right?" data-original-title="2003">
                                        <div className="timeline-icon">
                                            <img src='/asset/images/renovationGrid/timeline/reno-journey-5.png' />
                                        </div>
                                        <p>Ready to  move in !!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-center">
                            <button className="btn hire-us radius-50 my-3">BOOK FREE CONSULTATION</button>
                        </div>
                    </div>
                </div>
            </section>
            </>
    );
} 

export default TimelineContent;
